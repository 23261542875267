import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { resetPasswordAction } from 'store/admin/admin-action';
import { adminResetPasswordSchema } from 'validation/admin';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import { useAdminStore } from 'store/admin/admin-store';
import { useToasts } from 'react-toast-notifications';
import { resetPassword } from 'shared/field-data';
import { useLocation, useHistory } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Loader from 'components/Loader';
const ResetPassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const history = useHistory();
  const { loading } = useAdminStore();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(adminResetPasswordSchema),
  });

  const onSubmit = (data) => {
    resetPasswordAction(data, token, addToast, history);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading ? <Loader /> : null}

      <GridContainer
        justify='center'
        style={{ height: '100vh', alignItems: 'center' }}
      >
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader
              color='primary'
              style={{
                height: '50px',
                textAlign: 'left',
                justifyContent: 'center',
              }}
            >
              <h4>Reset Password</h4>
            </CardHeader>
            <CardBody>
              {resetPassword.map((input) => (
                <>
                  <CustomInput
                    key={input.name}
                    labelText={input.placeholder}
                    id={input.name}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: input.type,
                      ...register(input.name), // Register the input field with react-hook-form
                    }}
                    error={!!errors[input.name]} // Set error state based on whether there are errors for this field
                    success={!errors[input.name]} // Set success state based on whether there are no errors for this field
                  />
                  {errors[input.name] && (
                    <span className='error-message' style={{ color: 'red' }}>
                      {errors[input.name].message}
                    </span>
                  )}
                </>
              ))}{' '}
            </CardBody>

            <GridContainer justify='center'>
              <GridItem>
                <Button
                  type='submit'
                  color='primary' // Set the button color
                  disabled={loading} // Enable/disable the button
                >
                  Reset Password
                </Button>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default ResetPassword;

import { create } from 'zustand';

export const INTIAL_STATE = {
  modal: false,
  loading: false,

  loadingDeleteRole: false,
  pollList: [],
  totalCount: 0,
  modalType: '',
  error: null,
  pollOptionDetail: [],
  pollDetail: {},
  isModalOpen: false,
  csvFileUrl: '',
  downloadLoading: false,
};

export const usePollStore = create(() => ({
  ...INTIAL_STATE,
}));

export const useLoading = () => {
  return usePollStore((s) => ({
    loading: s.loading,
    loadingDeleteRole: s.loadingDeleteRole,
    pollList: s.pollList,
    pollOptionDetail: s.pollOptionDetail,
    isModalOpen: s.isModalOpen,
    totalCount: s.totalCount,
    pollDetail: s.pollDetail,
    error: s.error,
  }));
};

import React, { useEffect, useState } from "react";
//import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useAdvertiseStore } from "store/advertisement/advertise-store";
import { getAdvertiseListAction } from "store/advertisement/advertise-action";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
//import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
//import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loader from "components/Loader";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  IconButton,
} from "@material-ui/core";
//import VisibilityIcon from "@material-ui/icons/Visibility";
import { usePostListStyles } from "../Posts/PostStyle";
import Pagination from "components/CustomPagination/CustomPagination";
import SelectField from "components/SelectField/SelectField";
import { deleteAdvertise } from "store/advertisement/advertise-action";
import Modal from "featurecomponents/Modal";
import { sortingOptions } from "shared/field-data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
//import { SearchAdvertiseListAction } from "store/advertisement/advertise-action";

export default function AdvertiseList() {
  const commonClasses = usePostListStyles();
  const { addToast } = useToasts();

  const history = useHistory();
  const { advertiseList, loading, totalCount } = useAdvertiseStore();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //const [searchValue, setSearchValue] = useState("");
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedAdvertises, setselectedAdvertises] = useState([]);
  const [sortby, setSortby] = useState("a-z");
  const [type, setType] = useState("All");
  const [status, setStatus] = useState("All");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAdvertiseIds, setSelectedAdvertiseIds] = useState();
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);

  console.log("type:", setType);
  console.log("status:", setStatus);
  console.log("startDate:", setStartDate);
  console.log("endDate:", setEndDate);
  useEffect(() => {
    console.log("currentPage:", currentPage);
    getAdvertiseListAction(
      addToast,
      currentPage + 1,
      rowsPerPage,
      type,
      status,
      startDate,
      endDate,
      sortby
    );
  }, [currentPage, rowsPerPage, type, status, sortby]);
  /*  */
  /*  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      SearchAdvertiseListAction(
        addToast,
        searchValue,
        currentPage + 1,
        rowsPerPage
      );
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]); */

  /* const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    /* SearchAdvertiseListAction(
      addToast,
      event.target.value, 
      currentPage + 1,
      rowsPerPage
    );
  }; */
  const handleCreateBanner = () => {
    history.push("/admin/advertise");
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleSortbyChange = (event) => {
    setSortby(event.target.value);
  };
  /*  const handleView = () => {
    // console.log(id);
    history.push(`/admin/view-banner`);
  }; */
  /* const handleEdit = (id) => {
    console.log(id);
    history.push("/admin/advertise");
  }; */
  const handleCancelDelete = () => {
    setselectedAdvertises([]);
    setSelectedAll(false);
    setShowDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    deleteAdvertise({ advertisementIds: selectedAdvertiseIds }, addToast);
    setShowDeleteModal(false);
  };
  const handleDeleteAdvertise = (Id) => {
    setSelectedAdvertiseIds([Id]);
    setShowDeleteModal(true);
  };
  const handleDeleteSelectedAdvertises = () => {
    if (selectedAdvertises.length > 0) {
      setSelectedAdvertiseIds(selectedAdvertises);
      setShowDeleteModal(true);
    } else {
      addToast("Please select a group to delete", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const handleCheckboxChange = (event, advertiseId) => {
    const isChecked = event.target.checked;

    if (advertiseId === "all") {
      setSelectedAll(isChecked);
      setselectedAdvertises(
        isChecked ? advertiseList.map((advertise) => advertise._id) : []
      );
    } else {
      setselectedAdvertises((prevSelectedAdvertises) => {
        if (isChecked) {
          return [...prevSelectedAdvertises, advertiseId];
        } else {
          return prevSelectedAdvertises.filter((id) => id !== advertiseId);
        }
      });
    }
  };

  const handleStartDateChange = (date) => {
    const startDate = new Date(date);
    setStartDate(startDate);
    setEndDate(null);
  };

  const toggleStartDatePicker = () => {
    setOpenStartDatePicker(!openStartDatePicker);
  };

  const handleEndDateChange = (date) => {
    const endDate = new Date(date);
    setEndDate(endDate);
    getAdvertiseListAction(
      addToast,
      currentPage + 1,
      rowsPerPage,
      type,
      status,
      startDate,
      date,
      sortby
    );
  };
  const toggleEndDatePicker = () => {
    setOpenEndDatePicker(!openEndDatePicker);
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <Modal
        open={showDeleteModal}
        onClose={handleCancelDelete}
        title="Confirm Delete"
        content="Are you sure you want to delete this record?"
        actions={[
          { label: "Cancel", onClick: handleCancelDelete },
          { label: "Delete", onClick: handleConfirmDelete, color: "secondary" },
        ]}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <div style={{ display: "flex", alignItems: "center" }}>
                <h4>Advertisement List</h4>
              </div>
            </CardHeader>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                {/*   <TextField
                  label="Search"
                  placeholder="Search"
                  variant="outlined"
                  value={searchValue}
                  onChange={handleSearchChange}
                /> */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreateBanner}
                  style={{ marginLeft: "10px" }}
                >
                  Create Advertisement
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDeleteSelectedAdvertises}
                  style={{ marginLeft: "10px", marginRight: "15px" }}
                >
                  Delete Selected
                </Button>

                <GridContainer spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Start Date"
                        open={openStartDatePicker}
                        onClickOutside={() => setOpenStartDatePicker(false)}
                        onSelect={() => setOpenStartDatePicker(false)}
                      />

                      <IconButton onClick={toggleStartDatePicker}>
                        <CalendarTodayIcon />
                      </IconButton>
                    </FormControl>
                  </Grid>
                </GridContainer>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="End Date"
                      minDate={startDate}
                      disabled={!startDate}
                      open={openEndDatePicker}
                      onClickOutside={() => setOpenEndDatePicker(false)}
                      onSelect={() => setOpenEndDatePicker(false)}
                    />
                    {/* <InputAdornment position='end'> */}
                    <IconButton
                      onClick={toggleEndDatePicker}
                      disabled={!startDate}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                    <SelectField
                      style={{ marginLeft: "50px" }}
                      className={commonClasses.marginRight}
                      label="sortby"
                      value={sortby}
                      onChange={handleSortbyChange}
                      options={sortingOptions}
                    />
                  </FormControl>
                </Grid>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        key="checkbox-all"
                        checked={selectedAll}
                        onChange={(event) => handleCheckboxChange(event, "all")}
                      />
                    </TableCell>
                    <TableCell>Images</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>URL</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {advertiseList.map((advertise) => (
                    <TableRow key={advertise._id}>
                      <TableCell>
                        <Checkbox
                          key={`checkbox-${advertise._id}`}
                          checked={selectedAdvertises.includes(advertise._id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, advertise._id)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {" "}
                        {advertise?.FileUrl?.length > 0
                          ? advertise?.FileUrl?.map((item, index) => {
                              return (
                                <img
                                  key={index}
                                  src={item}
                                  alt="Image Preview"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    marginRight: "5px",
                                  }}
                                  // className={commonClasses.imagePreview}
                                />
                              );
                            })
                          : "-"}
                      </TableCell>
                      <TableCell>{advertise.title}</TableCell>
                      <TableCell>{advertise.description}</TableCell>
                      <TableCell>
                        {advertise.UrlLink?.map((link, index) => (
                          <a
                            key={index}
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Link {index + 1}
                          </a>
                        ))}
                      </TableCell>
                      <TableCell>
                        {/*     <IconButton
                          key={`view-${group._id}`}
                          onClick={() => handleView(group._id)}
                        >
                          <VisibilityIcon />
                        </IconButton> */}
                        {/*   <IconButton onClick={() => handleEdit(advertise._id)}>
                          <EditIcon />
                        </IconButton> */}
                        <IconButton
                          key={`delete-${advertise?._id}`}
                          onClick={() => handleDeleteAdvertise(advertise._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <Pagination
        count={Math.ceil(totalCount ?? 0)}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
}

import { create } from 'zustand';

export const INTIAL_STATE = {
  modal: false,
  loading: false,
  loadingDeleteRole: false,
  loadingVerify: false,
  userDetail: {},
  modalType: '',
  error: null,
  email: null,
  isForgotView: false,
  adminList: [],
  adminDetail: {},
};

export const useAdminStore = create(() => ({
  ...INTIAL_STATE,
}));

export const useLoading = () => {
  return useAdminStore((s) => ({
    loading: s.loading,
    adminList: s.adminList,
    adminDetail: s.adminDetail,
    loadingDeleteRole: s.loadingDeleteRole,
    error: s.error,
  }));
};

import React, { useEffect } from "react";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { usePermissionStore } from "store/permissions/permission-store";
import { useHistory, useParams } from "react-router-dom";
import CardHeader from "components/Card/CardHeader";
import { CreateGroupStyle } from "./CreateGroupStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { getPermissionById } from "store/permissions/permission-action";
import { useToasts } from "react-toast-notifications";
import Loader from "components/Loader";

const CreateGroup = () => {
  const { id } = useParams();

  const { permissionDetail, loading } = usePermissionStore();
  const commonClasses = CreateGroupStyle();
  const { addToast } = useToasts();
  const history = useHistory();
  useEffect(() => {
    getPermissionById(addToast, id);
  }, []);
  const handleBackToList = () => {
    history.push("/admin/group-list");
  };
  return (
    <GridContainer>
      {loading ? <Loader /> : null}

      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader title="Show Permission" />

          <CardHeader color="primary">
            <h5 className={commonClasses.cardTitleWhite}>Show Group</h5>
            <p className={commonClasses.cardCategoryWhite}></p>
          </CardHeader>
          <CardContent>
            <Typography variant="h5" component="h2">
              Title: {permissionDetail?.name}
            </Typography>

            <Typography variant="h6" component="h3">
              Description: {permissionDetail?.description}
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {permissionDetail?.permissions?.map((permission) => (
                <div key={permission._id} style={{ marginRight: "10px" }}>
                  <Typography color="textSecondary">
                    {permission?.name}
                  </Typography>
                </div>
              ))}
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBackToList}
            >
              Back to List
            </Button>
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CreateGroup;

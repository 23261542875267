import { useGroupStore } from "./group-store";
import api from "services/api";
import ROUTES from "utilities/api-routes";

export const getGroupListAction = async (
  addToast,
  page,
  limit,
  sortby,
  grouptype,
  status
) => {
  const { setState } = useGroupStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.groupList(page, limit, sortby, grouptype, status),
      false,
      addToast
    );
    if (result?.data) {
      return setState({
        groupList: result?.data || [],
        totalCount: result?.totalCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        groupList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      groupList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};
export const getGroupType = async (addToast) => {
  const { setState } = useGroupStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getGroupTypeApi(),
      false,
      addToast
    );
    if (result) {
      return setState({
        groupTypeList: result || [],

        loading: false,
      });
    } else {
      // addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        groupTypeList: [],

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      groupTypeList: [],

      loading: false,
      error: e.messages,
    });
  }
};
export const getStatus = async (addToast) => {
  const { setState } = useGroupStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getGroupStatusApi(),
      false,
      addToast
    );
    if (result) {
      return setState({
        statusList: result || [],

        loading: false,
      });
    } else {
      // addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        statusList: [],

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      statusList: [],

      loading: false,
      error: e.messages,
    });
  }
};
export const deleteGroup = async (ids, addToast) => {
  const { setState } = useGroupStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.delete(
      ROUTES.deleteGroupApi(),
      ids,
      addToast
    );
    console.log("result", result);
    if (result?.success) {
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      // setState({
      //   loading: false,
      // });
      getGroupListAction(addToast, 1, 5, "a-z", "All", "All");
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        groupList: [],
        totalCount: 0,
        loading: false,
      });
    }
  } catch (e) {
    return setState({
      groupList: [],
      totalCount: 0,
      loading: false,
      error: e.message,
    });
  }
};
export const updateGroupAction = async (data, id, addToast, history) => {
  const { setState } = useGroupStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.put(
      ROUTES.updateGroup(id),
      reqData,
      addToast
    );
    if (result) {
      setState({
        loading: false,
      });
      await addToast(result?.message, {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/group-list");
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    // addToast(e?.message, { appearance: "error", autoDismiss: true });
  }
};
export const SearchGroupListAction = async (addToast, name, page, limit) => {
  const { setState } = useGroupStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.searchGroup(name, page, limit),
      false,
      addToast
    );
    if (result?.results) {
      return setState({
        groupList: result?.results || [],
        totalSearchCount: result?.totalCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        groupList: [],
        totalSearchCount: 0,
        loading: false,
      });
    }
  } catch (e) {
    addToast(e?.message, { appearance: "error", autoDismiss: true });

    return setState({
      groupList: [],
      totalSearchCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};
export const GroupStatusApiAction = async (groupId, addToast) => {
  const { setState } = useGroupStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.patch(
      ROUTES.groupStatus(groupId),
      false,
      addToast
    );
    if (result?.success) {
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      getGroupListAction(addToast, 1, 5);
      return setState({
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        groupList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      groupList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};
export const getGroupById = async (addToast, id) => {
  const { setState } = useGroupStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getGroupByIdApi(id),
      false,
      addToast
    );
    if (result) {
      setState({
        groupDetail: result || {},

        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      setState({
        groupDetail: {},

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      groupDetail: {},

      loading: false,
      error: e.messages,
    });
  }
};
export const CreateNewGroupAction = async (data, addToast, history) => {
  const { setState } = useGroupStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.post(
      ROUTES.createGroup(),
      reqData,
      addToast
    );
    if (result?.success) {
      setState({
        loading: false,
      });
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      history.replace("/admin/group-list");
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    // addToast(e?.message, { appearance: "error", autoDismiss: true });
  }
};

export const updateStatusAction = async (data, addToast) => {
  const { setState } = useGroupStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    console.log("reqData", reqData);
    const { data: result } = await api.put(
      ROUTES.updateStatus(),
      reqData,
      addToast
    );
    if (result) {
      setState({
        loading: false,
      });
      getGroupListAction(addToast, 1, 5, "a-z", "All", "All");
      const actionMessage =
        data.status === "Approved"
          ? "marked as approved"
          : "marked as disapproved";
      await addToast(`Group successfully ${actionMessage}`, {
        appearance: "success",
        autoDismiss: true,
      });
      // history.push("/admin/group-list");
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    addToast(e?.message, { appearance: "error", autoDismiss: true });
  }
};

import React from 'react';
// @material-ui/core components
import { yupResolver } from '@hookform/resolvers/yup';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import { useToasts } from 'react-toast-notifications';

import { useForm } from 'react-hook-form';
import { roleInputs } from 'shared/field-data';
import { roleSchema } from 'validation/admin';
import { addNewRoleAction } from 'store/role/role-action';
import { useRoleStore } from 'store/role/role-store';
import { newRoleStyles } from './NewRoleStyle';
import { useHistory } from 'react-router-dom';

export default function NewRole() {
  //make a add yupResolver
  const { loading } = useRoleStore();
  const history = useHistory();
  const commonClasses = newRoleStyles();
  const { addToast } = useToasts();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(roleSchema),
  });
  const onSubmit = (data) => {
    console.log(data);
    addNewRoleAction(data, addToast, history); // Handle form submission here
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardHeader color='primary'>
                <h4 className={commonClasses.cardTitleWhite}>Add New Role</h4>
                <p className={commonClasses.cardCategoryWhite}>
                  {/* Complete your profile */}
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {roleInputs.map((input) => (
                    <CustomInput
                      key={input.name}
                      labelText={input.placeholder}
                      id={input.name}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: input.type,
                        ...register(input.name), // Register the input field with react-hook-form
                      }}
                      error={!!errors[input.name]} // Set error state based on whether there are errors for this field
                      success={!errors[input.name]}
                      // Set success state based on whether there are no errors for this field
                    />
                  ))}
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color='primary' type='submit' disabled={loading} className="btn-theme">
                  Add Role
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

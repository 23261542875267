import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import DeleteIcon from "@material-ui/icons/Delete";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import { useToasts } from "react-toast-notifications";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { getPermissionListAction } from "store/permissions/permission-action";
import { SearchPermissionListAction } from "store/permissions/permission-action";
import { usePermissionStore } from "store/permissions/permission-store";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { IconButton, Switch } from "@material-ui/core";
import Pagination from "components/CustomPagination/CustomPagination";
import { usePermissionListStyles } from "./PermissionListStyle";
import { activeInActivePermission } from "store/permissions/permission-action";
import Loader from "components/Loader";
import EditIcon from "@material-ui/icons/Edit";
import { deletePermissions } from "store/permissions/permission-action";
import VisibilityIcon from "@material-ui/icons/Visibility";

export default function PermissionList() {
  const { addToast } = useToasts();
  const commonClasses = usePermissionListStyles();
  const {
    permissionList,
    totalCount,
    loadingDeletePermission,
    loading,
  } = usePermissionStore();
  const history = useHistory();
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState("");
  // handle buttons
  const handleClick = () => {
    history.push("/admin/new-permission");
  };
  const handleSelectAll = () => {
    setSelectedPermissions(
      permissionList.map((permissions) => permissions._id)
    );
  };
  const handleDeselectAll = () => {
    setSelectedPermissions([]);
  };
  const handleDeleteSelectedPermissions = () => {
    if (selectedPermissions.length > 0) {
      const selectedPermissionId = { permissionIds: selectedPermissions };
      deletePermissions(selectedPermissionId, addToast);
    } else {
      addToast("Please select a permission to delete", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const handleDeleteIcon = (permissionId) => {
    const selectedPermissionId = { permissionIds: [permissionId] };
    deletePermissions(selectedPermissionId, addToast);
  };
  const handleCheckboxPermission = (event, permissionId) => {
    const isSelected = event.target.checked;
    if (isSelected) {
      setSelectedPermissions((prevSelectedRows) => [
        ...prevSelectedRows,
        permissionId,
      ]);
    } else {
      setSelectedPermissions((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== permissionId)
      );
    }
  };
  const handleView = (id) => {
    console.log(id);
    history.push(`/admin/view-permission/${id}`);
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    SearchPermissionListAction(addToast, event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };
  // Actions
  const handleEdit = (id) => {
    console.log(id);
    history.push(`/admin/edit-permission/${id}`);
  };

  useEffect(() => {
    getPermissionListAction(addToast, currentPage + 1, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const handleToggle = (permission_Id) => {
    activeInActivePermission(permission_Id, addToast);
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            style={{ display: "inline-block", width: "auto" }}
          >
            Add new Permission
          </Button>
          <Card>
            <CardHeader color="primary">
              <h4 className={commonClasses.cardTitleWhite}>Permission List</h4>
              <p className={commonClasses.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <div className={commonClasses.buttonstyle}>
                <TextField
                  //   label="Search"
                  placeholder="Search"
                  variant="outlined"
                  value={searchValue}
                  onChange={handleSearchChange}
                  className={`${commonClasses.buttonRight} normal`}
                />{" "}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSelectAll}
                  className={commonClasses.buttonRight}
                  disabled={!permissionList}
                >
                  Select All
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDeselectAll}
                  className={commonClasses.buttonRight}
                  disabled={!permissionList}
                >
                  Deselect All
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loadingDeletePermission || !permissionList}
                  onClick={handleDeleteSelectedPermissions}
                >
                  Delete Selected
                </Button>
              </div>
              <Table
                tableHeaderColor="primary"
                tableHead={["", "Name", "Actions", ""]}
                tableData={permissionList?.map((permissions) => [
                  <Checkbox
                    key={`checkbox-${permissions?._id}`}
                    checked={selectedPermissions?.includes(permissions?._id)}
                    onChange={(event) =>
                      handleCheckboxPermission(event, permissions?._id)
                    }
                  />,
                  permissions?.name,

                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    key={`actions-${permissions._id}`}
                  >
                    <IconButton
                      key={`view-${permissions._id}`}
                      onClick={() => handleView(permissions._id)}
                    >
                      <VisibilityIcon />
                    </IconButton>

                    <div style={{ margin: "0 5px" }}>
                      <Switch
                        key={`switch-${permissions._id}`}
                        checked={permissions?.isActive}
                        onChange={() => handleToggle(permissions._id)}
                        name="checkedA"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    </div>

                    <IconButton
                      key={`delete-${permissions._id}`}
                      onClick={() => handleDeleteIcon(permissions._id)}
                    >
                      <DeleteIcon />
                    </IconButton>

                    <IconButton
                      key={`edit-${permissions._id}`}
                      onClick={() => handleEdit(permissions._id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>,
                ])}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Pagination
        count={Math.ceil(totalCount)}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
}

import { create } from "zustand";

export const INTIAL_STATE = {
  modal: false,
  loading: false,
  loadingVerify: false,
  userDetail: {},
  modalType: "",
  error: null,
  isForgotView: false,
  loadingDeletePermission: false,
  permissionList: [],
  totalCount: 0,
  permissionDetail: {},
};

export const usePermissionStore = create(() => ({
  ...INTIAL_STATE,
}));

export const useLoading = () => {
  return usePermissionStore((s) => ({
    loading: s.loading,
    permissionList: s.permissionList,
    loadingDeletePermission: s.loadingDeletePermission,
    totalCount: s.totalCount,
    error: s.error,
    permissionDetail: s.permissionDetail,
  }));
};

export const search = () => {
  return usePermissionStore((s) => ({
    searchPermission: s.searchPermission,
  }));
};
export const deleteselectpermission = () => {
  return usePermissionStore((s) => ({
    deletePermission: s.deletePermission,
    error: s.error,
  }));
};

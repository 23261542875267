import { useAdvertiseStore } from "./advertise-store";
import api from "services/api";
import ROUTES from "utilities/api-routes";

export const CreateNewAdvertiseAction = async (data, addToast, history) => {
  const { setState } = useAdvertiseStore;
  setState({ loading: true });
  // const reqData = {
  //   title: data.title,
  //   description: data.description,
  //   FileUrl: [data.FileUrl],
  //   UrlLink: [data.UrlLink],
  //   type: data.type,
  //   groupId: data.groupId,
  // };

  try {
    const response = await api.post(ROUTES.createAdvertise(), data);
    const result = response.data;

    if (result.success) {
      setState({ loading: false });
      addToast(result.message, { appearance: "success", autoDismiss: true });
      history.push("/admin/advertiseList")
    } else {
      setState({ loading: false });
      addToast(result.message, { appearance: "error", autoDismiss: true });
    }
  } catch (error) {
    setState({ loading: false });
    addToast("An error occurred while creating advertisement.", {
      appearance: "error",
      autoDismiss: true,
    });
    console.error("Error creating advertisement:", error);
  }
};

export const getAdvertiseListAction = async (
  addToast,
  page,
  limit,
  type,
  status,
  startDate,
  endDate,
  sortby
) => {
  const { setState } = useAdvertiseStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.advertiseList(
        page,
        limit,
        type,
        status,
        startDate,
        endDate,
        sortby
      ),
      false,
      addToast
    );

    if (result?.advertisements) {
      return setState({
        advertiseList: result?.advertisements || [], // Adjust data extraction from the response
        totalCount: result?.totalCount || 0, // Adjust data extraction from the response
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        advertiseList: [],
        totalCount: 0,
        loading: false,
      });
    }
  } catch (e) {
    return setState({
      advertiseList: [],
      totalCount: 0,
      loading: false,
      error: e.message, // Fix the property name to "message"
    });
  }
};
export const deleteAdvertise = async (advertisementIds, addToast) => {
  const { setState } = useAdvertiseStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.delete(
      ROUTES.deleteAdvertise(),
      advertisementIds,
      addToast
    );
    console.log("result", result);
    if (result?.success) {
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      // setState({
      //   loading: false,
      // });
      getAdvertiseListAction(addToast, 1, 5, "All", "All", null, null, "a-z");
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        advertiseList: [],
        totalCount: 0,
        loading: false,
      });
    }
  } catch (e) {
    return setState({
      advertiseList: [],
      totalCount: 0,
      loading: false,
      error: e.message,
    });
  }
};

export const SearchAdvertiseListAction = async (
  addToast,
  name,
  page,
  limit
) => {
  const { setState } = useAdvertiseStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.searchAdvertise(name, page, limit),
      false,
      addToast
    );
    if (result?.data) {
      return setState({
        advertiseList: result?.data || [],
        totalCount: result?.totalCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });
      debugger;
      return setState({
        advertiseList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    addToast(e?.message, { appearance: "error", autoDismiss: true });

    return setState({
      advertiseList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};

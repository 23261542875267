import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button.js';

import logo from 'assets/img/forex-logo.png';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { loginAction } from 'store/admin/admin-action';
import { adminLoginSchema } from 'validation/admin';
import CustomInput from 'components/CustomInput/CustomInput';
// import RegularButton from 'components/CustomButtons/Button';
import { useAdminStore } from 'store/admin/admin-store';
import { useToasts } from 'react-toast-notifications';
import { loginInputs } from 'shared/field-data';
import { Link, useHistory } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
// import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import Loader from 'components/Loader';
const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  container: {
    display: 'flex',

    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'center',
  },
  gridItem: {
    maxWidth: '100%',
    justifyContent: 'center',
  },
};

const useStyles = makeStyles(styles);
const Login = () => {
  const classes = useStyles();
  const { loading } = useAdminStore();
  const { addToast } = useToasts();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(adminLoginSchema),
  });

  const onSubmit = (data) => {
    loginAction(data, addToast, history);
  };

  return (
    <>
      {loading ? <Loader /> : null}

      <GridContainer
        justify='center'
        style={{
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <div className='mt-5 text-center'><img src={logo} className='login-logo' /></div>
            <h4 className='ps-4 ms-2 mb-0 text-16 font-weight-600 mt-5'>Login</h4>
            <CardBody>
              <GridContainer justify='center'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {loginInputs.map((input) => (
                    <CustomInput
                      key={input.name}
                      labelText={input.placeholder}
                      id={input.name}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: input.type,
                        ...register(input.name), // Register the input field with react-hook-form
                      }}
                      error={!!errors[input.name]} // Set error state based on whether there are errors for this field
                      success={!errors[input.name]} // Set success state based on whether there are no errors for this field
                    />
                  ))}
                  <Link to='/forgot-password' className='mt-3 d-inline-block'>Forgot Password?</Link>

                  <CardFooter className={classes.cardFooter}>
                    <Button type='submit' color='primary' disabled={loading} className="btn-theme mt-4">
                      Login
                    </Button>
                  </CardFooter>
                </form>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default Login;

import { create } from "zustand";

export const INTIAL_STATE = {
  modal: false,
  loading: false,
  loadingVerify: false,
  userDetail: {},
  modalType: "",
  error: null,
  isForgotView: false,
  reviewData: {},
  reviewList: [],
  groupTypeList: [],
  statusList: [],
  totalRatings: 0,
  groupDetail: {},
};

export const useReviewStore = create(() => ({
  ...INTIAL_STATE,
}));

export const useLoading = () => {
  return useReviewStore((s) => ({
    loading: s.loading,
    reviewList: s.reviewList,
    totalRatings: s.totalRatings,
    error: s.error,
    GroupDetail: s.GroupDetail,
    groupTypeList: s.groupTypeList,
    statusList: s.statusList,
    reviewData: s.reviewData,
  }));
};

export const search = () => {
  return useReviewStore((s) => ({
    searchGroup: s.searchGroup,
  }));
};

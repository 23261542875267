import { useAdminStore } from "./admin-store";
import api from "services/api";
import ROUTES from "utilities/api-routes";

export const loginAction = async (data, addToast, history) => {
  const { setState } = useAdminStore;
  setState({ loading: true });

  const reqData = { ...data };
  try {
    const { data: result } = await api.post(ROUTES.login(), reqData, addToast);
    if (result?.data?.token) {
      console.log(result?.user, "adminDetail");
      localStorage.setItem("accessToken", result?.data?.token);
      localStorage.setItem("admin", JSON.stringify(result?.data?.user));
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      setState({ adminDetail: result?.data?.user, loading: false });
      history?.replace("/admin/dashboard");
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    addToast(e.message, { appearance: "error", autoDismiss: true });

    return { error: e.message };
  }
};

export const forgotPasswordAction = async (data, addToast) => {
  const { setState } = useAdminStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.post(
      ROUTES.forgotPassword(),
      reqData,
      addToast
    );
    if (result?.success) {
      setState({
        email: data?.email,

        loading: false,
      });
    } else {
      setState({ loading: false });
    }

    addToast(result?.message, { appearance: "success", autoDismiss: true });
  } catch (e) {
    setState({ loading: false });
    addToast(e.message, { appearance: "error", autoDismiss: true });
  }
};

export const resetPasswordAction = async (data, token, addToast, history) => {
  const { setState } = useAdminStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.patch(
      ROUTES.resetPassword(token),
      reqData,
      addToast
    );

    if (result.success) {
      addToast(result?.message, { appearance: "success", autoDismiss: true });

      history?.replace("/admin/dashboard");
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
    setState({ loading: false });
  } catch (e) {
    setState({ loading: false });
    addToast(e.message, { appearance: "error", autoDismiss: true });

    return { error: e.message };
  }
};
export const getAdminList = async (addToast, page, limit) => {
  const { setState } = useAdminStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getAdminListApi(page, limit),
      false,
      addToast
    );
    if (result?.data) {
      return setState({
        adminList: result?.data || [],
        totalCount: result?.totalCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        adminList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      adminList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};

export const activeInActiveAdmin = async (userId, addToast) => {
  const { setState } = useAdminStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.patchUpdate(
      ROUTES.activeInActiveAdminId(userId),
      false,
      addToast
    );
    if (result?.success) {
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      getAdminList(addToast, 1, 5);
      return setState({
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        adminList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      adminList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};

export const searchAdminName = async (addToast, name) => {
  const { setState } = useAdminStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.searchAdminByName(name),
      false,
      addToast
    );
    if (result?.results) {
      return setState({
        adminList: result?.results || [],
        totalCount: result?.totalSearchCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        adminList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    addToast(e?.message, { appearance: "error", autoDismiss: true });

    return setState({
      adminList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};
export const getAdminById = async (addToast, id) => {
  const { setState } = useAdminStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getAdminByIdApi(id),
      false,
      addToast
    );
    if (result) {
      setState({
        adminDetail: result || {},

        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      setState({
        adminDetail: {},

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      adminDetail: {},

      loading: false,
      error: e.messages,
    });
  }
};
export const addNewAdminAction = async (data, addToast, history) => {
  const { setState } = useAdminStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.post(
      ROUTES.addNewAdmin(),
      reqData,
      addToast
    );
    if (result?.success) {
      setState({
        loading: false,
      });
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      history.replace("/admin/user-list");
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    addToast(e?.message, { appearance: "error", autoDismiss: true });
  }
};
export const updateAdminAction = async (data, id, addToast, history) => {
  const { setState } = useAdminStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.put(
      ROUTES.updateAdmin(id),
      reqData,
      addToast
    );
    if (result?.success) {
      setState({
        loading: false,
      });
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      history.replace("/admin/user-list");
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    addToast(e?.message, { appearance: "error", autoDismiss: true });
  }
};
export const deleteAdmin = async (Id, addToast) => {
  const { setState } = useAdminStore;
  setState({
    loadingDeleteRole: true,
  });

  try {
    const { data: result } = await api.delete(
      ROUTES.deleteAdminSelectedId(),
      Id,
      addToast
    );
    if (result?.success) {
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      getAdminList(addToast, 1, 5);
      return setState({
        loadingDeleteRole: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        adminList: [],
        totalCount: 0,

        loadingDeleteRole: false,
      });
    }
  } catch (e) {
    return setState({
      adminList: [],
      totalCount: 0,
      loadingDeleteRole: false,
      error: e.messages,
    });
  }
};

import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ROUTES from 'utilities/api-routes';
import useSWR from 'swr';
import { fetcherSWR } from 'services/api';
import { useToasts } from 'react-toast-notifications';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useCreatePollStyles } from './CreateEditPollStyle';
// import { addNewPollAction } from 'store/poll/poll-action';
import { useHistory, useParams } from 'react-router-dom';
import { pollSchema } from 'validation/poll';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getPollDetail } from 'store/poll/poll-action';
import { usePollStore } from 'store/poll/poll-store';
import Loader from 'components/Loader';
import { updatePollAction } from 'store/poll/poll-action';
import { addNewPollAction } from 'store/poll/poll-action';

export default function CreateEditPoll() {
  const { id } = useParams(); // If you're using React Router, get the ID parameter
  const isEdit = !!id;
  const { pollDetail, loading } = usePollStore();
  const { setState } = usePollStore;

  const {
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    resolver: yupResolver(pollSchema),
  });
  const adminData = JSON.parse(localStorage.getItem('admin'));
  const adminId = (adminData && adminData._id) || '';
  console.log('adminId', adminId);
  const [pollData, setPollData] = useState({
    Title: '',
    Description: '',
    pollType: '',
    status: '',
    options: [],
    startDate: null,
    endDate: null,
    groupId: '',
    adminId: adminId,
  });
  const { addToast } = useToasts();
  const history = useHistory();
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
  const commonClasses = useCreatePollStyles();
  console.log(pollData, 'pollData');
  const { data: pollTypeList } = useSWR(
    ROUTES.getPollTypeApi(),
    (url) => fetcherSWR(url, false, addToast),
    { revalidateOnFocus: false } // Optional configuration options
  );
  const { data: groupList } = useSWR(
    ROUTES.allGroupList(),
    (url) => fetcherSWR(url, false, addToast),
    { revalidateOnFocus: false } // Optional configuration options
  );

  useEffect(() => {
    if (isEdit) {
      getPollDetail(id, addToast);
    }
    setState({
      isModalOpen: false,
    });
  }, [id, isEdit]);
  useEffect(() => {
    setState({
      isModalOpen: false,
    });
    if (pollDetail) {
      //  setPollData(pollDetail);
      const {
        Title,
        Description,
        pollType,
        status,
        //   options: pollDetail?.options?.map((option) => option.option),
        startDate,
        endDate,
        // groupId,
      } = pollDetail;
      const options = pollDetail?.options?.map((option) => option.option);
      const groupId = pollDetail?.groupId?._id || '';

      setPollData({
        Title,
        Description,
        pollType,
        status,
        options,
        startDate,
        endDate,
        groupId,
      });

      setValue('Title', pollDetail?.Title);
      setValue('Description', pollDetail?.Description);
      setValue('pollType', pollDetail?.pollType);
      setValue('status', pollDetail?.status);
      setValue('startDate', pollDetail?.startDate);
      setValue('endDate', pollDetail?.endDate);
      setValue('groupId', groupId);
      setValue('options', options);
      // pollDetail.options.forEach((option, index) => {
      //   setValue(`options[${index}]`, option);
      // });
    }
  }, [pollDetail, setValue]);
  const toggleStartDatePicker = () => {
    setOpenStartDatePicker(!openStartDatePicker);
  };

  const toggleEndDatePicker = () => {
    setOpenEndDatePicker(!openEndDatePicker);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setPollData({
      ...pollData,
      [name]: value,
    });
    setValue(name, value);
    trigger(name);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...pollData.options];
    updatedOptions[index] = value;
    setPollData({
      ...pollData,
      options: updatedOptions,
    });
    setValue(`options[${index}]`, value);
    trigger('options');
  };

  const handleStartDateChange = (date) => {
    const startDate = new Date(date);

    setPollData({
      ...pollData,
      endDate: '',
      startDate: startDate,
    });
    setValue('startDate', startDate);
    trigger('startDate'); // Trigger validation after updating startDate
  };

  const handleEndDateChange = (date) => {
    const endDate = new Date(date);

    setPollData({
      ...pollData,
      endDate: endDate,
    });
    setValue('endDate', endDate);
    trigger('endDate'); // Trigger validation after updating endDate
  };
  const onSubmit = (data) => {
    console.log(data);
    // Perform form submission logic here
    if (pollData.pollType === 'All') {
      pollData.groupId = null;
    }
    const areOptionsValid = pollData?.options?.every(
      (option) => option.trim() !== ''
    ); // Check if all options are not empty

    if (!areOptionsValid) {
      // Display error message or handle the invalid options
      console.log('Options cannot be empty');
      addToast('Options cannot be empty', {
        appearance: 'error',
        autoDismiss: true,
      });
      return; // Do not proceed with form submission
    }
    console.log('Submitted Poll Data:', pollData);

    const pollDataWithAdminId = {
      ...pollData,
      adminId: adminId,
    };
    if (isEdit) {
      // Update existing poll
      setState({
        isModalOpen: false,
      });
      updatePollAction(pollDataWithAdminId, id, addToast, history);
    } else {
      // Add new poll
      addNewPollAction(pollDataWithAdminId, addToast, history);
      setState({
        isModalOpen: false,
        pollDetail: {},
      });
    }
  };
  const handleCancel = () => {
    setState({
      isModalOpen: false,
      pollDetail: {},
    });

    history.push('/admin/poll');
  };
  const handleGroupIdChange = (event, newValue) => {
    const groupId = newValue ? newValue._id : '';
    setPollData({ ...pollData, groupId });
    setValue('groupId', groupId);
    trigger('groupId'); // Trigger validation after updating groupId
  };
  return (
    <>
      {loading ? <Loader /> : null}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel htmlFor='group-name'>Poll Title</InputLabel>
            <br />
            <TextField
              name='Title'
              //  label='Poll Title'
              variant='outlined'
              fullWidth
              value={pollData?.Title || ''}
              onChange={handleChange}
              // error={!!errors.Title}
              // helperText={errors.Title}
              className={`${commonClasses.inputField} w-100`}
            />
            {errors.Title && (
              <span className={commonClasses.errorMessage}>
                {errors.Title.message}
              </span>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel htmlFor='group-name'>Description</InputLabel>
            <br />
            <TextField
              name='Description'
              //  label='Description'
              variant='outlined'
              fullWidth
              multiline
              rows={4}
              value={pollData?.Description || ''}
              onChange={handleChange}
            />
            {errors.Description && (
              <span className={commonClasses.errorMessage}>
                {errors.Description.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor='group-name'>Poll Type</InputLabel>
            <br />
            <FormControl variant='outlined' fullWidth>
              <Select
                name='pollType'
                value={pollData?.pollType}
                onChange={handleChange}
                //  label='Poll Type'
                //  required
              >
                {pollTypeList?.data?.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.pollType && (
              <span className={commonClasses.errorMessage}>
                {errors.pollType.message}
              </span>
            )}
          </Grid>

          {pollData?.pollType === 'Group' && (
            <Grid item xs={12}>
              <InputLabel>Group</InputLabel>

              <FormControl variant='outlined' fullWidth>
                <Autocomplete
                  options={groupList?.data?.groups || []}
                  getOptionLabel={(group) => group?.title}
                  value={
                    groupList?.data?.groups?.find(
                      (group) => group._id === pollData.groupId
                    ) || null
                  }
                  onChange={handleGroupIdChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      //  label={pollData?.groupId ? '' : 'Group'}
                      variant='outlined'
                      //   required={pollData.pollType === 'Group'}
                      className={commonClasses.input}
                    />
                  )}
                />
              </FormControl>
              {errors.groupId && (
                <span className={commonClasses.errorMessage}>
                  {errors.groupId.message}
                </span>
              )}
            </Grid>
          )}
          {/* Start Date */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={pollData?.status === 'Active'}
                  onChange={(event) => {
                    const status = event.target.checked ? 'Active' : 'Inactive'; // Update the status
                    setPollData({ ...pollData, status });
                    setValue('status', status);
                    trigger('status');
                  }}
                />
              }
              label='Status'
              labelPlacement='start'
            />{' '}
            {errors.status && (
              <span className={commonClasses.errorMessage}>
                {errors.status.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <DatePicker
                selected={pollData?.startDate}
                onChange={handleStartDateChange}
                dateFormat='MM/dd/yyyy'
                placeholderText='Start Date'
                open={openStartDatePicker}
                onClickOutside={() => setOpenStartDatePicker(false)}
                onSelect={() => setOpenStartDatePicker(false)}
              />
              {/* <InputAdornment position='end'> */}
              <IconButton onClick={toggleStartDatePicker}>
                <CalendarTodayIcon />
              </IconButton>
              {/* </InputAdornment> */}
            </FormControl>
            {errors.startDate && (
              <span className={commonClasses.errorMessage}>
                {errors.startDate.message}
              </span>
            )}
          </Grid>

          {/* End Date Picker */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <DatePicker
                selected={pollData?.endDate}
                onChange={handleEndDateChange}
                dateFormat='MM/dd/yyyy'
                placeholderText='End Date'
                minDate={pollData?.startDate}
                disabled={!pollData?.startDate}
                open={openEndDatePicker}
                onClickOutside={() => setOpenEndDatePicker(false)}
                onSelect={() => setOpenEndDatePicker(false)}
              />
              {/* <InputAdornment position='end'> */}
              <IconButton
                onClick={toggleEndDatePicker}
                disabled={!pollData?.startDate}
              >
                <CalendarTodayIcon />
              </IconButton>
              {/* </InputAdornment> */}
            </FormControl>
            {errors.endDate && (
              <span className={commonClasses.errorMessage}>
                {errors.endDate.message}
              </span>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Options</InputLabel>
            <br />
            <TextField
              name='numberOfOptions'
              //  label='Number of Options'
              type='number'
              variant='outlined'
              fullWidth
              value={pollData?.options?.length || 0}
              onChange={(event) => {
                const numberOfOptions = parseInt(event.target.value, 10) || 0;
                const updatedOptions = Array.from(
                  { length: numberOfOptions },
                  (_, index) =>
                    (pollData?.options && pollData.options[index]) || ''
                );
                setPollData({
                  ...pollData,
                  options: updatedOptions,
                });

                setValue('options', updatedOptions); // Set value for options field
                trigger('options');
              }}

              // required
            />
            {errors?.options && (
              <span className={commonClasses?.errorMessage}>
                {errors?.options?.message}
              </span>
            )}
          </Grid>

          {pollData?.options?.map((option, index) => (
            <Grid item xs={12} key={index}>
              <TextField
                //   label={`Option ${index + 1}`}
                variant='outlined'
                fullWidth
                value={option}
                onChange={(event) =>
                  handleOptionChange(index, event.target.value)
                }
              />
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              // onClick={() => handleSave(true, "save&back")}
              style={{ marginLeft: '1rem' }}
            >
              {isEdit ? 'Update' : 'Save & Back'}{' '}
              {/* Change button text based on mode */}
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={handleCancel}
              style={{ marginLeft: '1rem' }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, InputLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
// import Loader from "components/Loader";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useReviewStore } from "store/reviews/review-store";
import { useToasts } from "react-toast-notifications";
import { getReviewListByReviewIdAction } from "store/reviews/review-action";
import { updateReviewAction } from "store/reviews/review-action";
//import { useToasts } from "react-toast-notifications";
const EditReview = () => {
  const { reviewData } = useReviewStore();
  const { addToast } = useToasts();
  console.log(reviewData, "adasdsadsdsdf");
  const { id } = useParams();
  const history = useHistory();
  console.log(history, "history");
  const [reviewDetails, setReviewDetails] = useState(reviewData);
  console.log(reviewDetails, "reviewDetails");
  useEffect(() => {
    getReviewListByReviewIdAction(addToast, id);
  }, [id]);

  const handleUpdateStatusReview = async () => {
    updateReviewAction(
      addToast,
      reviewData?.groupId,
      {
        ratingIds: [id],
        status: reviewDetails?.status,
      },
      history
    );
  };

  return (
    <>
      {/* {loading ? <Loader /> : null} */}
      <Card className="main-wrapper">
        <CardContent>
          <CardHeader color="primary">
            <h4>Add/Edit Review</h4>
          </CardHeader>
        </CardContent>
        <CardBody>
          <form>
            <div style={{ marginBottom: "2rem" }}>
              <InputLabel htmlFor="group-name">Review</InputLabel>
              <div>{reviewData?.review}</div>
              <br />
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <InputLabel htmlFor="description">Ratings</InputLabel>
              <div>{reviewData?.ratingValue}</div>
              <br />
            </div>

            <label htmlFor="isApproved">Status</label>

            <Switch
              id="isApproved"
              label="Status"
              name="status"
              checked={reviewDetails?.status === "Approved" ? true : false}
              onChange={(e) => {
                console.log(e.target.checked, "checked");
                const checked = e.target.checked;
                const newStatus = checked ? "Approved" : "Disapproved";
                setReviewDetails({
                  ...reviewData,
                  status: newStatus,
                });
              }}
            />

            <div style={{ marginTop: "1rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateStatusReview}
                style={{ marginLeft: "1rem" }}
              >
                Save
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
};

export default EditReview;

// commonStyles.js
import { makeStyles } from '@material-ui/core/styles';

export const useCreatePollStyles = makeStyles(() => ({
  input: {
    paddingTop: '1rem', // Adjust as needed
  },
  errorMessage: {
    color: 'red',
  },
  inputField: {
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   width: 170,
    // },
  },
}));

import { useReviewStore } from "./review-store";
import api from "services/api";
import ROUTES from "utilities/api-routes";

export const getReviewListAction = async (
  addToast,
  id,
  page,
  limit,
  sortby
) => {
  const { setState } = useReviewStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.reviewList(id, page, limit, sortby),
      false,
      addToast
    );
    if (result?.data) {
      return setState({
        reviewList: result?.data || [],
        totalRatings: result?.totalRatings,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        reviewList: [],
        totalRatings: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      reviewList: [],
      totalRatings: 0,
      loading: false,
      error: e.messages,
    });
  }
};
export const updateReviewAction = async (addToast, groupId, data, history) => {
  const { setState } = useReviewStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.put(
      ROUTES.updateRatingStatus(),
      reqData,
      addToast
    );
    debugger;
    if (result) {
      setState({
        loading: false,
      });
      await addToast(result?.message, {
        appearance: "success",
        autoDismiss: true,
      });
      history.push(`/admin/review-list/${groupId}`);
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    // addToast(e?.message, { appearance: "error", autoDismiss: true });
  }
};
export const getReviewListByReviewIdAction = async (addToast, id) => {
  const { setState } = useReviewStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.reviewListById(id),
      false,
      addToast
    );
    if (result?.data) {
      return setState({
        reviewData: result?.data || {},
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        reviewData: {},
        loading: false,
      });
    }
  } catch (e) {
    return setState({
      reviewData: {},
      loading: false,
    });
  }
};

import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useToasts } from "react-toast-notifications";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { permissionInputs } from "shared/field-data";
import { permissionSchema } from "validation/admin";
import { addNewPermissionAction } from "store/permissions/permission-action";
import { usePermissionStore } from "store/permissions/permission-store";
import Loader from "components/Loader";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function NewPermission() {
  //make a add yupResolver
  const { loading } = usePermissionStore();
  const history = useHistory();
  const classes = useStyles();
  const { addToast } = useToasts();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(permissionSchema),
  });
  const onSubmit = (data) => {
    console.log(data);
    addNewPermissionAction(data, addToast, history); // Handle form submission here
  };
  return (
    <div>
      {loading ? <Loader /> : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Add New Permission</h4>
                <p className={classes.cardCategoryWhite}>
                  {/* Complete your profile */}
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {permissionInputs.map((input) => (
                    <CustomInput
                      key={input.name}
                      labelText={input.placeholder}
                      id={input.name}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: input.type,
                        ...register(input.name), // Register the input field with react-hook-form
                      }}
                      error={!!errors[input.name]} // Set error state based on whether there are errors for this field
                      success={!errors[input.name]}
                      // Set success state based on whether there are no errors for this field
                    />
                  ))}
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" className="btn-theme" type="submit" disabled={loading}>
                  Add Permission
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import { create } from 'zustand';

export const INTIAL_STATE = {
  modal: false,
  loading: false,

  loadingDeleteRole: false,
  roleList: [],
  totalCount: 0,
  modalType: '',
  error: null,
  roleDetail: {},
};

export const useRoleStore = create(() => ({
  ...INTIAL_STATE,
}));

export const useLoading = () => {
  return useRoleStore((s) => ({
    loading: s.loading,
    loadingDeleteRole: s.loadingDeleteRole,
    roleList: s.roleList,
    totalCount: s.totalCount,
    roleDetail: s.roleDetail,
    error: s.error,
  }));
};

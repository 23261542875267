import ROUTES from 'utilities/api-routes';
import { usePostStore } from './post-store';
import api from 'services/api';

export const getPostList = async (
  addToast,
  page,
  limit,
  status,
  postType,
  sorting
) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getPostListApi(page, limit, status, postType, sorting),
      false,
      addToast
    );
    if (result?.data) {
      return setState({
        postList: result?.data || [],
        totalCount: result?.totalCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        postList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      postList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};
export const getPostType = async (addToast) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getPostTypeApi(),
      false,
      addToast
    );
    if (result) {
      return setState({
        postTypeList: result || [],

        loading: false,
      });
    } else {
      // addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        postTypeList: [],

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      postTypeList: [],

      loading: false,
      error: e.messages,
    });
  }
};
export const getStatus = async (addToast) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getStatusApi(),
      false,
      addToast
    );
    if (result) {
      return setState({
        statusList: result || [],

        loading: false,
      });
    } else {
      // addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        statusList: [],

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      statusList: [],

      loading: false,
      error: e.messages,
    });
  }
};

export const deletePost = async (id, addToast) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.delete(
      ROUTES.deletePostApi(),
      id,
      addToast
    );
    if (result?.deletedCount) {
      addToast(result?.message, { appearance: 'success', autoDismiss: true });
      getPostList(addToast, 1, 5, 'All', 'All', 'asce');
      return setState({
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        postList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      postList: [],
      totalCount: 0,
      loading: false,
      error: e.message,
    });
  }
};

export const activeInActivePost = async (id, addToast) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.patchUpdate(
      ROUTES.activeInActivePostId(id),
      false,
      addToast
    );
    if (result?.success) {
      addToast(result?.message, { appearance: 'success', autoDismiss: true });
      getPostList(addToast, 1, 5);
      return setState({
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        postList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      postList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};
export const getPostById = async (addToast, id) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });
  try {
    const { data: result } = await api.get(
      ROUTES.getPostByIdApi(id),
      false,
      addToast
    );
    if (result) {
      setState({
        postDetail: result || {},

        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      setState({
        postDetail: {},

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      postDetail: {},

      loading: false,
      error: e.messages,
    });
  }
};

export const getAllComments = async (
  addToast,
  id,
  page,
  limit,
  status,
  sorting,
  startDate,
  endDate
) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getAllCommentsApi(
        id,
        page,
        limit,
        status,
        sorting,
        startDate,
        endDate
      ),
      false,
      addToast
    );
    if (result?.success) {
      return setState({
        commentList: result?.data || [],
        totalCount: result?.totalComments || 0,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        commentList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      commentList: [],
      totalCount: 0,
      loading: false,
      error: e.message,
    });
  }
};

export const searchPostTitle = async (addToast, name, page, limit) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.searchPost(name, page, limit),
      false,
      addToast
    );

    if (result?.results) {
      return setState({
        postList: result?.results || [],
        totalCount: result?.totalResults,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        postList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      postList: [],
      totalCount: 0,
      loading: false,
      error: e.message,
    });
  }
};
export const updatePostAction = async (data, id, addToast, history) => {
  const { setState } = usePostStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.patch(
      ROUTES.updatePost(id),
      reqData,
      addToast
    );
    if (result) {
      setState({
        loading: false,
      });
      addToast(result?.message, { appearance: 'success', autoDismiss: true });
      history.replace('/admin/posts');
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: 'error', autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
  }
};
export const activeInActiveComment = async (id, post_id, addToast) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.patch(
      ROUTES.activeInActiveCommentId(id),
      false,
      addToast
    );
    if (result?.success) {
      addToast(result?.message, { appearance: 'success', autoDismiss: true });
      getAllComments(addToast, post_id, 1, 5);
      return setState({
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        commentList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      commentList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};

export const deleteComment = async (id, post_id, addToast) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.delete(
      ROUTES.deleteCommentApi(),
      id,
      addToast
    );
    if (result?.deletedCount) {
      addToast(result?.message, { appearance: 'success', autoDismiss: true });
      getAllComments(addToast, post_id, 1, 5, 'All', 'asce');
      return setState({
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        commentList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      commentList: [],
      totalCount: 0,
      loading: false,
      error: e.message,
    });
  }
};

export const getCommentById = async (addToast, id) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });
  try {
    const { data: result } = await api.get(
      ROUTES.getCommentByIdApi(id),
      false,
      addToast
    );
    if (result) {
      setState({
        commentDetail: result?.data || {},

        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      setState({
        commentDetail: {},

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      commentDetail: {},

      loading: false,
      error: e.messages,
    });
  }
};

export const updateCommentAction = async (
  data,
  id,
  post_id,
  addToast,
  history
) => {
  const { setState } = usePostStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.patch(
      ROUTES.updateCommentApi(id),
      reqData,
      addToast
    );
    if (result) {
      setState({
        loading: false,
      });
      addToast(result?.message, { appearance: 'success', autoDismiss: true });
      history.replace(`/admin/view-post/${post_id}`);
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: 'error', autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
  }
};

export const searchCommentAction = async (addToast, name, page, limit, id) => {
  const { setState } = usePostStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.searchComment(name, page, limit, id),
      false,
      addToast
    );
    if (result?.results) {
      return setState({
        commentList: result?.results || [],
        totalCount: result?.totalCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        commentList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      commentList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};

import { create } from "zustand";

export const INTIAL_STATE = {
  modal: false,
  loading: false,
  loadingVerify: false,
  userDetail: {},
  modalType: "",
  error: null,
  isForgotView: false,
  loadingDeleteGroup: false,
  groupList: [],
  groupTypeList: [],
  statusList: [],
  totalCount: 0,
  groupDetail: {},
};

export const useGroupStore = create(() => ({
  ...INTIAL_STATE,
}));

export const useLoading = () => {
  return useGroupStore((s) => ({
    loading: s.loading,
    groupList: s.groupList,
    loadingDeleteGroup: s.loadingDeleteGroup,
    totalCount: s.totalCount,
    error: s.error,
    GroupDetail: s.GroupDetail,
    groupTypeList: s.groupTypeList,
    statusList: s.statusList,
  }));
};

export const search = () => {
  return useGroupStore((s) => ({
    searchGroup: s.searchGroup,
  }));
};
export const deleteselectgroup = () => {
  return useGroupStore((s) => ({
    deleteGroup: s.deleteGroup,
    error: s.error,
  }));
};

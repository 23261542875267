import React, { useEffect, useState } from 'react';
import {
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  CardContent,
  Card,
  CardMedia,
} from '@material-ui/core';
import { useAdminStore } from 'store/admin/admin-store';
import { useHistory, useParams } from 'react-router-dom';
import { getAdminById } from 'store/admin/admin-action';
import { useToasts } from 'react-toast-notifications';
import Loader from 'components/Loader';

function ViewAdmin() {
  const { id } = useParams();

  const [user, setUser] = useState({ name: '', email: '', isActive: false });
  const { adminDetail, loading } = useAdminStore();
  const history = useHistory();
  const { addToast } = useToasts();
  useEffect(() => {
    getAdminById(addToast, id);
  }, []);
  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSubmit = () => {
    event.preventDefault();
    // Submit the user data here
    console.log(user);
    history.push('/admin/user-list');
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <form
        onSubmit={handleSubmit}

      >
        <div className='main-wrapper'>
          <div>
            <TextField
              name='username'
              label='Username'
              value={adminDetail.username || ''}
              disabled
              variant='outlined'
              margin='normal'
              className='center auto'
              style={{ width: '300px' }}
              fullWidth
            />
          </div>
          <div>
            <TextField
              name='email'
              label='Email'
              value={adminDetail.email || ''}
              disabled
              variant='outlined'
              margin='normal'
              //  className='center auto'
              style={{ width: '300px' }}
              fullWidth
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={adminDetail.isActive || false}
                  onChange={handleChange}
                  name='isActive'
                  color='primary'
                />
              }
              label='Active'
            />
          </div>

          {adminDetail?.Advertisement > 0 ? (
            <Typography variant='h5'>Advertisements</Typography>
          ) : null}

          {adminDetail?.Advertisement?.map((ad, index) => (
            <Card key={index}>
              <CardContent>
                <Typography variant='h5'>{ad?.title}</Typography>
                <Typography variant='body2'>{ad?.description}</Typography>
                <CardMedia
                  component='img'
                  alt={ad?.title}
                  height='140'
                  image={ad?.image[0]}
                />
                <Typography variant='body2'>
                  <a href={ad?.UrlLink[0]}>Link</a>
                </Typography>
              </CardContent>
            </Card>
          ))}
          {adminDetail?.sentMessages > 0 ? (
            <Typography variant='h5'>Sent Messages</Typography>
          ) : null}

          {adminDetail?.sentMessages?.map((message, index) => (
            <Card key={index}>
              <CardContent>
                <Typography variant='body1'>{message?.content}</Typography>
                {message?.uploadFile?.map((file, fileIndex) => (
                  <CardMedia
                    key={fileIndex}
                    component='img'
                    alt={`File ${fileIndex}`}
                    height='140'
                    image={file}
                  />
                ))}
              </CardContent>
            </Card>
          ))}
          <div>
            <Button type='submit' variant='contained' color='primary' className='mt-5 mb-3'>
              Back to List
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default ViewAdmin;

import React, { useEffect, useState } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { useToasts } from 'react-toast-notifications';
import Pagination from 'components/CustomPagination/CustomPagination';
import Loader from 'components/Loader';
import { usePostStore } from 'store/posts/post-store';
import { usePostDetailStyles } from './PostDetailStyle';
import { useHistory, useParams } from 'react-router-dom';
import { getAllComments, deleteComment } from 'store/posts/post-action';
import Table from 'components/Table/Table.js';
import {
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from 'featurecomponents/Modal';
import TextField from '@material-ui/core/TextField';
import { searchCommentAction } from 'store/posts/post-action';
import useSWR from 'swr';
import { fetcherSWR } from 'services/api';
import ROUTES from 'utilities/api-routes';
import SelectField from 'components/SelectField/SelectField';
import { sortby } from 'shared/field-data';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { delayedSearch } from 'helper';
import { formatDateTime } from 'helper';

export default function PostDetail() {
  const history = useHistory();
  const { addToast } = useToasts();
  const commonClasses = usePostDetailStyles();
  const { id } = useParams();
  const { commentList, totalCount, loading } = usePostStore();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPostIds, setSelectedPostIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('All');
  const [sorting, setSorting] = useState('asce');
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);

  const { data: statusList } = useSWR(
    ROUTES.getStatusApi(),
    (url) => fetcherSWR(url, false, addToast),
    { revalidateOnFocus: false } // Optional configuration options
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setEndDate('');
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    getAllComments(
      addToast,
      id,
      currentPage + 1,
      rowsPerPage,
      status,
      sorting,
      startDate,
      date
    );
  };

  console.log(statusList, 'statusList');
  const handleSelectAll = () => {
    setSelectedRows(commentList?.map((post) => post._id));
    setSelectedAll(true);
  };

  const handleDeselectAll = () => {
    setSelectedRows([]);
    setSelectedAll(false);
  };

  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      const selectedRoleId = { ids: selectedRows };
      setSelectedPostIds(selectedRows); // Set the selected post IDs
      setShowDeleteModal(true);
      console.log(selectedRoleId, 'selectedRoleId');
      //  deletePost(selectedRoleId, addToast);
    } else {
      addToast('Please select a comment to delete', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  const handleCheckboxChange = (event, roleId) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, roleId]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== roleId)
      );
    }
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    getAllComments(
      addToast,
      id,
      currentPage + 1,
      rowsPerPage,
      status,
      sorting,
      startDate,
      endDate
    );
  }, [id, currentPage, rowsPerPage, status, sorting]);

  const handleDelete = (Id) => {
    //   const selectedId = { ids: [Id] };
    setSelectedPostIds([Id]); // Set the selected post IDs
    setShowDeleteModal(true);
  };
  const handleConfirmDelete = () => {
    // Perform the delete action
    console.log(selectedPostIds, 'selectedPostIds');
    deleteComment({ ids: selectedPostIds }, id, addToast);
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setSelectedRows([]);
    setSelectedAll(false);
    setShowDeleteModal(false);
  };
  const handleEdit = (id) => {
    console.log(id);
    history.push(`/admin/edit-comment/${id}`);
  };
  // const handleSearchChange = (event) => {
  //   setSearchValue(event.target.value);
  // };
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);

    // Call the delayedSearch function after 2 seconds of no typing
    delayedSearch(
      addToast,
      value,
      currentPage + 1,
      rowsPerPage,
      id,
      searchCommentAction
    );
  };
  const handleView = (id) => {
    console.log(id);
    history.push(`/admin/view-comment/${id}`);
  };
  const handleGoBack = () => {
    history.push(`/admin/posts`);
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSortingChange = (event) => {
    setSorting(event.target.value);
  };
  const toggleStartDatePicker = () => {
    setOpenStartDatePicker(!openStartDatePicker);
  };

  const toggleEndDatePicker = () => {
    setOpenEndDatePicker(!openEndDatePicker);
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <Modal
        open={showDeleteModal}
        onClose={handleCancelDelete}
        title='Confirm Delete'
        content='Are you sure you want to delete this record?'
        actions={[
          { label: 'Cancel', onClick: handleCancelDelete },
          { label: 'Delete', onClick: handleConfirmDelete, color: 'secondary' },
        ]}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='primary'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4 className={commonClasses.cardTitleWhite}>Comment List</h4>
                <div
                  style={{
                    border: '1px solid black',

                    width: 'fit-content',
                    marginLeft: 'auto',
                  }}
                >
                  <Button color='primary' onClick={handleGoBack}>
                    Back
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className={commonClasses.buttonstyle}>
                <TextField
                  label='Search by comment'
                  variant='outlined'
                  value={searchValue}
                  onChange={handleSearchChange}
                />{' '}
                <Button
                  className={commonClasses.buttonstyle}
                  variant='contained'
                  color='primary'
                  disabled={
                    loading ||
                    !Array.isArray(commentList) ||
                    commentList?.length === 0
                  }
                  onClick={handleDeleteSelected}
                >
                  Delete Selected
                </Button>
                <div className={commonClasses.dateStyle}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat='MM/dd/yyyy'
                    placeholderText='Start Date'
                    open={openStartDatePicker}
                    onClickOutside={() => setOpenStartDatePicker(false)}
                    onSelect={() => setOpenStartDatePicker(false)}
                  />
                  <InputAdornment position='end'>
                    <IconButton onClick={toggleStartDatePicker}>
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                </div>
                <div className={commonClasses.dateStyle}>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat='MM/dd/yyyy'
                    placeholderText='End Date'
                    minDate={startDate}
                    disabled={!startDate}
                    open={openEndDatePicker}
                    onClickOutside={() => setOpenEndDatePicker(false)}
                    onSelect={() => setOpenEndDatePicker(false)}
                  />
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={toggleEndDatePicker}
                      disabled={!startDate}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                </div>
                <div className={commonClasses.marginRight}>
                  <SelectField
                    label='Status'
                    value={status}
                    onChange={handleStatusChange}
                    options={
                      Array.isArray(statusList?.data) ? statusList?.data : []
                    }
                  />
                </div>
                <div className={commonClasses.marginRight}>
                  <SelectField
                    label='Sorting'
                    value={sorting}
                    onChange={handleSortingChange}
                    options={sortby}
                  />
                </div>
              </div>
              {Array.isArray(commentList) && commentList.length > 0 ? (
                <Table
                  tableHeaderColor='primary'
                  tableHead={[
                    <Checkbox
                      key='select-all-checkbox'
                      checked={selectedAll}
                      onChange={(event) =>
                        event.target.checked
                          ? handleSelectAll()
                          : handleDeselectAll()
                      }
                    />,
                    'Comment ID',
                    'Comment Content',
                    'Post Title',
                    'User Name',
                    'Date and Time',
                    'Status',
                    'Actions',
                  ]}
                  tableData={(Array.isArray(commentList)
                    ? commentList
                    : []
                  ).map((comment) => [
                    // Add key prop for each element in the array
                    <Checkbox
                      key={comment?._id}
                      checked={selectedRows.includes(comment._id)}
                      onChange={(event) =>
                        handleCheckboxChange(event, comment._id)
                      }
                    />,
                    comment?._id,
                    comment?.content,
                    comment?.posts[0]?.title,
                    comment?.author[0]?.FirstName +
                      ' ' +
                      comment?.author[0]?.LastName,
                    // formatDate(comment?.createdAt),
                    comment?.createdAt
                      ? formatDateTime(comment?.createdAt)
                      : '', // Date

                    comment?.status,
                    <div
                      key={`role-${comment._id}`}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <IconButton
                        key={`view-${comment._id}`}
                        onClick={() => handleView(comment._id)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        key={`edit-${comment._id}`}
                        onClick={() => handleEdit(comment._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      {/* <Switch
                        key={`switch-${comment._id}`}
                        checked={comment?.isApproved}
                        onChange={() => handleToggle(comment._id)}
                        name='checkedA'
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      /> */}

                      <IconButton
                        key={`delete-${comment._id}`}
                        onClick={() => handleDelete(comment._id)}
                      >
                        {/* Add delete column */}
                        <DeleteIcon />
                      </IconButton>
                    </div>,
                  ])}
                />
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <p>Data not found</p>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Pagination
        count={Math.ceil(totalCount / rowsPerPage)}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
}

import React, { useEffect, useState } from 'react';
// @material-ui/core components

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { useToasts } from 'react-toast-notifications';
import Pagination from 'components/CustomPagination/CustomPagination';

import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
// import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from '@material-ui/icons/Edit';
import Loader from 'components/Loader';
import { usePostStore } from 'store/posts/post-store';
import { usePostListStyles } from './PostStyle';
import { getPostList } from 'store/posts/post-action';
import { deletePost } from 'store/posts/post-action';
import Modal from 'featurecomponents/Modal';
import SelectField from 'components/SelectField/SelectField';
import { getPostType } from 'store/posts/post-action';
import { getStatus } from 'store/posts/post-action';
import { sortingOptions } from 'shared/field-data';
import { delayedSearch } from 'helper';
import { searchPostTitle } from 'store/posts/post-action';

export default function Posts() {
  const { addToast } = useToasts();
  const commonClasses = usePostListStyles();

  const {
    postList,
    totalCount,
    loading,
    statusList,
    postTypeList,
  } = usePostStore();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPostIds, setSelectedPostIds] = useState([]);
  const [status, setStatus] = useState('All');
  const [sorting, setSorting] = useState('a-z');
  const [postType, setPostType] = useState('All');
  const history = useHistory();

  useEffect(() => {
    getPostType(addToast);
    getStatus(addToast);
  }, []);
  useEffect(() => {
    console.log('currentPage:', currentPage);

    getPostList(
      addToast,
      currentPage + 1,
      rowsPerPage,
      status,
      postType,
      sorting
    );
  }, [currentPage, rowsPerPage, status, postType, sorting]);

  const handleSelectAll = () => {
    setSelectedRows(postList?.map((post) => post._id));
    setSelectedAll(true);
  };

  const handleDeselectAll = () => {
    setSelectedRows([]);
    setSelectedAll(false);
  };

  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      const selectedRoleId = { ids: selectedRows };
      setSelectedPostIds(selectedRows); // Set the selected post IDs
      setShowDeleteModal(true);
      console.log(selectedRoleId, 'selectedRoleId');
      //  deletePost(selectedRoleId, addToast);
    } else {
      addToast('Please select a admin to delete', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  const handleCheckboxChange = (event, roleId) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, roleId]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== roleId)
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };
  //make a handle delete function

  const handleDelete = (Id) => {
    //   const selectedId = { ids: [Id] };
    setSelectedPostIds([Id]); // Set the selected post IDs
    setShowDeleteModal(true);

    //  deletePost(selectedId, addToast); // Add your delete logic here
    // Add your delete logic here
  };
  const handleConfirmDelete = () => {
    // Perform the delete action
    console.log(selectedPostIds, 'selectedPostIds');
    deletePost({ ids: selectedPostIds }, addToast);
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setSelectedRows([]);
    setSelectedAll(false);
    setShowDeleteModal(false);
  };

  const handleView = (id) => {
    console.log(id);
    history.push(`/admin/view-post/${id}`);
  };
  const handleEdit = (id) => {
    console.log(id);
    // getAdminById(addToast, id, history, true);
    history.push(`/admin/edit-post/${id}`);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);

    // Call the delayedSearch function after 2 seconds of no typing
    delayedSearch(
      addToast,
      value,
      currentPage + 1,
      rowsPerPage,
      0,
      searchPostTitle
    );
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    //  getPostList(addToast, currentPage, rowsPerPage, event.target.value, '', '');

    // Add your logic for handling status change here
  };

  const handleSortingChange = (event) => {
    setSorting(event.target.value);
    // getPostList(addToast, currentPage, rowsPerPage, '', '', event.target.value);

    // Add your logic for handling sorting change here
  };
  const handlePostTypeChange = (event) => {
    setPostType(event.target.value);
    //getPostList(addToast, currentPage, rowsPerPage, '', event.target.value, '');
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <Modal
        open={showDeleteModal}
        onClose={handleCancelDelete}
        title='Confirm Delete'
        content='Are you sure you want to delete this record?'
        actions={[
          { label: 'Cancel', onClick: handleCancelDelete },
          { label: 'Delete', onClick: handleConfirmDelete, color: 'secondary' },
        ]}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='primary'>
              <h4 className={commonClasses.cardTitleWhite}>Post List</h4>
              <p className={commonClasses.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <div className={commonClasses.buttonstyle}>
                <TextField
                  label='Search by title'
                  variant='outlined'
                  value={searchValue}
                  onChange={handleSearchChange}
                  className={commonClasses.buttonRight}
                />{' '}
                <Button
                  className={commonClasses.marginRight}
                  variant='contained'
                  color='primary'
                  disabled={
                    loading ||
                    !Array.isArray(postList) ||
                    postList?.length === 0
                  }
                  onClick={handleDeleteSelected}
                >
                  Delete Selected
                </Button>
                <div className={commonClasses.marginRight}>
                  <SelectField
                    label='Post type'
                    value={postType}
                    onChange={handlePostTypeChange}
                    options={postTypeList}
                  />
                </div>
                <div className={commonClasses.marginRight}>
                  <SelectField
                    label='Status'
                    value={status}
                    onChange={handleStatusChange}
                    options={statusList}
                  />
                </div>
                <SelectField
                  className={commonClasses.marginRight}
                  label='Sorting'
                  value={sorting}
                  onChange={handleSortingChange}
                  options={sortingOptions}
                />
              </div>

              {Array.isArray(postList) && postList.length > 0 ? (
                <Table
                  tableHeaderColor='primary'
                  tableHead={[
                    <Checkbox
                      key='select-all-checkbox'
                      checked={selectedAll}
                      onChange={(event) =>
                        event.target.checked
                          ? handleSelectAll()
                          : handleDeselectAll()
                      }
                    />,
                    'Title',
                    'Post Type',
                    'Author Name',
                    'Email',
                    'Status',
                    'Actions',
                  ]} // Adjusted table headers
                  tableData={(Array.isArray(postList) ? postList : []).map(
                    (post) => [
                      <Checkbox
                        key={post?._id}
                        checked={selectedRows.includes(post._id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, post._id)
                        }
                      />, // Add key prop for each element in the array
                      post?.title || 'N/A',
                      post?.type || 'N/A',
                      post?.author[0]?.FirstName
                        ? post?.author[0]?.FirstName +
                          ' ' +
                          post?.author[0]?.LastName
                        : 'N/A',
                      post?.author[0]?.email || 'N/A',
                      post?.status,
                      <div
                        key={`role-${post._id}`}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Button
                          key={`view-${post._id}`}
                          onClick={() => handleView(post._id)}
                        >
                          View Comment
                          {/* <VisibilityIcon /> */}
                        </Button>
                        <IconButton
                          key={`edit-${post?._id}`}
                          onClick={() => handleEdit(post?._id)}
                        >
                          <EditIcon />
                        </IconButton>
                        {/* <Switch
                          key={`switch-${post?._id}`}
                          checked={post?.isApproved}
                          onChange={() => handleToggle(post?._id)}
                          name='checkedA'
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        /> */}

                        <IconButton
                          key={`delete-${post?._id}`}
                          onClick={() => handleDelete(post?._id)}
                        >
                          {/* Add delete column */}
                          <DeleteIcon />
                        </IconButton>
                      </div>,
                    ]
                  )}
                />
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <p>Data not found</p>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Pagination
        count={Math.ceil(totalCount ?? 0)}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
}

import * as yup from "yup";

export const adminLoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .matches(/^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+com$/, "Email is required")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});
export const adminForgotSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .matches(/^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+com$/, "Email is required")
    .required("Email is required"),
});
export const adminResetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});
// add name and description schema function
export const roleSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  // description: yup.string().required('Description is required'),
});
//validation for admin
export const adminInputSchema = yup.object().shape({
  username: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .matches(/^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+com$/, "Email is required")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});
export const adminEditSchema = yup.object().shape({
  username: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .matches(/^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+com$/, "Email is required")
    .required("Email is required"),
});
export const permissionSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
});
/* export const groupValidationSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  image: yup.mixed().when("groupType", {
    is: "Broker",
    then: yup.mixed().required("Image is required when Group Type is Broker"),
    otherwise: yup.mixed(),
  }),
}); */
export const groupValidationSchema = yup.object().shape({
  title: yup.string().required("Group Name is required"),
  type: yup.string().required("Group Type is required"),
  // image: yup.mixed().when("groupType", {
  //   is: "Broker",
  //   then: yup
  //     .mixed()
  //     .test(
  //       "fileSize",
  //       "Image file is required",
  //       (value) => value && value.size > 0
  //     )
  //     .required(),
  // }),
});

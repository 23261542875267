import { create } from 'zustand';

export const INTIAL_STATE = {
  modal: false,
  loading: false,
  error: null,
  email: null,
  postList: [],
  postDetail: {},
  commentList: [],
  commentDetail: {},
  loadingDeleteRole: false,
  totalCount: 0,
  postTypeList: [],
  statusList: [],
};

export const usePostStore = create(() => ({
  ...INTIAL_STATE,
}));

export const useLoading = () => {
  return usePostStore((s) => ({
    loading: s.loading,
    postList: s.postList,
    loadingDeleteRole: s.loadingDeleteRole,
    postDetail: s.postDetail,
    commentList: s.commentList,
    commentDetail: s.commentDetail,
    totalCount: s.totalCount,
    postTypeList: s.postTypeList,
    statusList: s.statusList,
    error: s.error,
  }));
};

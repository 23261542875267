import React, { useEffect, useState } from 'react';
// @material-ui/core components
// import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { useToasts } from 'react-toast-notifications';
import Pagination from 'components/CustomPagination/CustomPagination';

import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import { useAdminListStyles } from './AdminListStyle';
import { useAdminStore } from 'store/admin/admin-store';
import { getAdminList } from 'store/admin/admin-action';
import { deleteAdmin } from 'store/admin/admin-action';
import { activeInActiveAdmin } from 'store/admin/admin-action';
import { searchAdminName } from 'store/admin/admin-action';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Loader from 'components/Loader';

export default function AdminList() {
  const { addToast } = useToasts();
  const commonClasses = useAdminListStyles();

  const { adminList, totalCount, loading } = useAdminStore();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  const history = useHistory();

  const handleClick = () => {
    // Redirect to the next page
    history.push('/admin/new-admin');
  };
  const handleSelectAll = () => {
    setSelectedRows(adminList?.map((role) => role._id));
  };

  const handleDeselectAll = () => {
    setSelectedRows([]);
  };

  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      const selectedRoleId = { ids: selectedRows };
      deleteAdmin(selectedRoleId, addToast);
    } else {
      addToast('Please select a admin to delete', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  const handleCheckboxChange = (event, roleId) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, roleId]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== roleId)
      );
    }
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    searchAdminName(addToast, event.target.value);
    // Add your search input logic here
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };
  //make a handle delete function

  useEffect(() => {
    getAdminList(addToast, currentPage + 1, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const handleDelete = (Id) => {
    const selectedId = { ids: [Id] };
    deleteAdmin(selectedId, addToast); // Add your delete logic here
    // Add your delete logic here
  };
  const handleToggle = (Id) => {
    activeInActiveAdmin(Id, addToast);
  };
  const handleView = (id) => {
    console.log(id);
    history.push(`/admin/view-admin/${id}`);
  };
  const handleEdit = (id) => {
    console.log(id);
    // getAdminById(addToast, id, history, true);
    history.push(`/admin/edit-admin/${id}`);
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleClick}
            // className={commonClasses.buttonLeft}
            style={{ display: 'inline-block', width: 'auto' }}
          >
            Add User
          </Button>
          <Card>
            <CardHeader color='primary'>
              <h4 className={commonClasses.cardTitleWhite}>User List</h4>
              <p className={commonClasses.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <div className={commonClasses.buttonstyle}>
                <TextField
                  label='Search'
                  variant='outlined'
                  value={searchValue}
                  onChange={handleSearchChange}
                  className={commonClasses.buttonRight}
                />{' '}
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSelectAll}
                  className={commonClasses.buttonRight}
                  disabled={
                    !Array.isArray(adminList) || adminList?.length === 0
                  }
                >
                  Select All
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleDeselectAll}
                  className={commonClasses.buttonRight}
                  disabled={
                    !Array.isArray(adminList) || adminList?.length === 0
                  }
                >
                  Deselect All
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={
                    loading ||
                    !Array.isArray(adminList) ||
                    adminList?.length === 0
                  }
                  onClick={handleDeleteSelected}
                >
                  Delete Selected
                </Button>
              </div>

              {Array.isArray(adminList) && adminList.length > 0 ? (
                <Table
                  tableHeaderColor='primary'
                  tableHead={['', 'Name', 'email', 'Actions']}
                  tableData={(Array.isArray(adminList) ? adminList : []).map(
                    (user) => [
                      <Checkbox
                        key={user._id}
                        checked={selectedRows.includes(user._id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, user._id)
                        }
                      />, // Add key prop for each element in the array
                      user?.username,
                      user?.email,
                      // user?.roles.map((role, index) => (
                      //   <span
                      //     className={`${commonClasses.permissionName} custom-permission`}
                      //     key={role._id}
                      //     style={{
                      //       marginRight:
                      //         index !== user?.roles?.length - 1 ? '10px' : '0',
                      //     }}
                      //   >
                      //     {role?.name}

                      //     {/* Add condition to display comma only if it's not the last item */}
                      //   </span>
                      // )),
                      <div
                        key={`role-${user._id}`}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <IconButton
                          key={`view-${user._id}`}
                          onClick={() => handleView(user._id)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          key={`edit-${user._id}`}
                          onClick={() => handleEdit(user._id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <Switch
                          key={`switch-${user._id}`}
                          checked={user?.isActive}
                          onChange={() => handleToggle(user._id)}
                          name='checkedA'
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />

                        <IconButton
                          key={`delete-${user._id}`}
                          onClick={() => handleDelete(user._id)}
                        >
                          {/* Add delete column */}
                          <DeleteIcon />
                        </IconButton>
                      </div>,
                    ]
                  )}
                />
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <p>Data not found</p>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Pagination
        count={Math.ceil(totalCount ?? 0)}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
}

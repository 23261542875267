import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import ROUTES from "utilities/api-routes";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useSWR from "swr";
import { fetcherSWR } from "services/api";
import { useToasts } from "react-toast-notifications";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useAdvertiseStore } from "store/advertisement/advertise-store";
import Loader from "components/Loader";
import { CreateNewAdvertiseAction } from "store/advertisement/advertise-action";
import { useCreatePollStyles } from "views/Poll-Voting/Add-Edit/CreateEditPollStyle";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  imagePreview: {
    maxWidth: "100%",
    maxHeight: "200px",
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Advertise = () => {
  const commonClasses = useCreatePollStyles();
  const classes = useStyles();
  const { loading } = useAdvertiseStore();
  const { addToast } = useToasts();
  const history = useHistory();
  const [formData, setFormData] = useState({
    group: "",
    title: "",
    image: null,
    description: "",
    targetURL: "",
  });
  const adminData = JSON.parse(localStorage.getItem("admin"));
  const adminId = (adminData && adminData._id) || "";

  const { data: groupList } = useSWR(
    ROUTES.allGroupList(),
    (url) => fetcherSWR(url, false, addToast),
    { revalidateOnFocus: false } // Optional configuration options
  );

  console.log(groupList, "groupListSaaasdd");
  // console.log(groupList, "uuuuuuuuuuuuuuuuuuuuuuuuuu");
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    const newValue = name === "image" ? files[0] : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const getBase64Image = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let FileImage = await getBase64Image(formData.image);

    const data = {
      title: formData.title,
      description: formData.description,
      FileUrl: [FileImage],
      UrlLink: [formData.targetURL],
      type: "Group",
      groupId: formData?.group?._id, // Replace this with actual group ID
      adminId: adminId,
    };
    await CreateNewAdvertiseAction(data, addToast, history); // Assuming addToast function is available
  };
  // const handleGroupIdChange = () => {};
  const handleGroupChange = (event, value) => {
    console.log(value, "asdasfsfsdfsdfs");
    setFormData({ ...formData, group: value }); // Update selected group in form data
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <Card>
        <CardContent>
          <CardHeader color="primary">
            <h4>Create New Banner</h4>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: "1rem" }}>
                <InputLabel htmlFor="group-name">Group</InputLabel>
                <br />
                <FormControl variant="outlined" className={classes.inputField}>
                  <InputLabel>Select Group</InputLabel>
                  <Autocomplete
                    options={groupList?.data?.groups || []}
                    getOptionLabel={(group) => group?.title}
                    value={formData.group}
                    onChange={handleGroupChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className={commonClasses.input}
                      />
                    )}
                  />
                </FormControl>
              </div>

              <div style={{ marginBottom: "1rem" }}>
                <InputLabel htmlFor="group-name">Title</InputLabel>
                <br />
                <TextField
                  className={classes.inputField}
                  label="Banner Title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
              </div>
              <div>
                <InputLabel htmlFor="group-name">Image</InputLabel>
                <br></br>
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={handleChange}
                  required
                />
                {formData.image && (
                  <img
                    src={URL.createObjectURL(formData.image)}
                    alt="Image Preview"
                    className={classes.imagePreview}
                  />
                )}
              </div>
              <br></br>
              <div style={{ marginBottom: "1rem" }}>
                <InputLabel htmlFor="group-name">Description</InputLabel>
                <br />
                <TextField
                  className={classes.inputField}
                  label="Banner Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <InputLabel htmlFor="group-name">URL</InputLabel>
                <br />
                <TextField
                  className={classes.inputField}
                  label="Banner Target URL"
                  name="targetURL"
                  value={formData.targetURL}
                  onChange={handleChange}
                  required
                  variant="outlined"
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Create Banner
              </Button>
            </form>
          </CardBody>
        </CardContent>
      </Card>
    </>
  );
};

export default Advertise;

/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
//import Dashboard from "@material-ui/icons/Dashboard";
import PermissionIcon from "@material-ui/icons/Security";
import PersonIcon from "@material-ui/icons/Person";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import DescriptionIcon from "@material-ui/icons/Description";
// import LibraryBooks from '@material-ui/icons/LibraryBooks';
// import BubbleChart from '@material-ui/icons/BubbleChart';
// import LocationOn from '@material-ui/icons/LocationOn';
// import Notifications from '@material-ui/icons/Notifications';
// import Unarchive from '@material-ui/icons/Unarchive';
// import Language from '@material-ui/icons/Language';
// // core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
// // import UserProfile from 'views/UserProfile/UserProfile.js';
// import TableList from 'views/TableList/TableList.js';
// import Typography from 'views/Typography/Typography.js';
// import Icons from 'views/Icons/Icons.js';
// import Maps from 'views/Maps/Maps.js';
// import NotificationsPage from 'views/Notifications/Notifications.js';
// import UpgradeToPro from 'views/UpgradeToPro/UpgradeToPro.js';
// // core components/views for RTL layout
// import RTLPage from 'views/RTLPage/RTLPage.js';
// import NewRole from 'views/Role/NewRole';
import RoleList from "views/Role/RoleList";
import AdminList from "views/Admin/AdminList/AdminList";
import AddNewAdmin from "views/Admin/NewAdmin/AddNewAdmin";
import ViewAdmin from "views/Admin/View/ViewAdmin";
import AdminEdit from "views/Admin/Edit/AdminEdit";
import NewRole from "views/Role/NewRole";
import ViewRole from "views/Role/View/ViewRole";
import EditRole from "views/Role/Edit/EditRole";
import PermissionList from "views/Permission/PermissionList";
import PeopleIcon from "@material-ui/icons/People";
import NewPermission from "views/Permission/NewPermission";
import GroupIcon from "@material-ui/icons/Group";
import ViewPermission from "views/Permission/View/ViewPermission";
import EditPermission from "views/Permission/Edit/EditPermission";
import CreateGroup from "views/Group/Create/CreateGroup";
import EditGroup from "views/Group/Edit/EditGroup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import Posts from "views/Posts/Post";
import UpdatePost from "views/Posts/Edit/UpdatePost";
import PostDetail from "views/Posts/View/PostDetail";
import CommentsUpdate from "views/Comments/Edit/CommentsUpdate";
import ViewComment from "views/Posts/ViewComment/ViewComment";
import GroupList from "views/Group/GroupList";
import PollIcon from "@material-ui/icons/Poll";
import PollList from "views/Poll-Voting/PollList/PollList";
import CreateEditPoll from "views/Poll-Voting/Add-Edit/CreateEditPoll";
import Advertise from "views/Advertisement/Advertise";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AdvertiseList from "views/Advertisement/AdvertiseList";
import StarIcon from "@material-ui/icons/Star";
import ReviewList from "views/Review/ReviewList";
import EditReview from "views/Review/Edit/EditReview";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: DashboardPage,
    layout: "/admin",
    excludeFromSidebar: false,
  },

  // {
  //   path: '/user',
  //   name: 'User Profile',
  //   rtlName: 'ملف تعريفي للمستخدم',
  //   icon: Person,
  //   component: UserProfile,
  //   layout: '/admin',
  //   excludeFromSidebar: false,
  // },
  {
    path: "/new-admin",
    name: "",
    rtlName: "إنشاء مشرف",
    icon: Person,
    component: AddNewAdmin,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/new-permission",
    name: "",
    rtlName: "إنشاء مشرف",
    icon: Person,
    component: NewPermission,
    layout: "/admin",
    excludeFromSidebar: true,
  },

  {
    path: "/new-role",
    name: "",
    rtlName: "إنشاء مشرف",
    icon: Person,
    component: NewRole,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/edit-poll/:id",
    name: "",
    rtlName: "إنشاء مشرف",
    icon: Person,
    component: CreateEditPoll,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/add-poll",
    name: "",
    rtlName: "إنشاء مشرف",
    icon: Person,
    component: CreateEditPoll,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  //
  {
    path: "/view-permission/:id",
    name: "",
    rtlName: "عرض الدور",
    icon: Person,
    component: ViewPermission,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/edit-permission/:id",
    name: "",
    rtlName: "تعديل الدور",
    icon: Person,
    component: EditPermission,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  //
  {
    path: "/view-role/:id",
    name: "",
    rtlName: "عرض الدور",
    icon: Person,
    component: ViewRole,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/edit-role/:id",
    name: "",
    rtlName: "تعديل الدور",
    icon: Person,
    component: EditRole,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/view-admin/:id",
    name: "View Admin",
    rtlName: "عرض المشرف",
    icon: Person,
    component: ViewAdmin,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/view-post/:id",
    name: "View Post",
    rtlName: "عرض المشرف",
    icon: Person,
    component: PostDetail,
    layout: "/admin",
    excludeFromSidebar: true,
  },

  {
    path: "/view-comment/:id",
    name: "View Comment",
    rtlName: "عرض المشرف",
    icon: Person,
    component: ViewComment,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  /*  {
    path: "/view-banner/:id",
    name: "View Banner",
    rtlName: "عرض البانر",
    icon: Person,
    component: ViewBanner,
    layout: "/admin",
    excludeFromSidebar: true,
  }, */
  {
    path: "/edit-admin/:id",
    name: "Edit Admin",
    rtlName: "تعديل المشرف",
    icon: Person,
    component: AdminEdit,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/edit-post/:id",
    name: "Edit Post",
    rtlName: "تعديل المشرف",
    icon: Person,
    component: UpdatePost,
    layout: "/admin",
    excludeFromSidebar: true,
  },

  {
    path: "/edit-comment/:id",
    name: "Edit Post",
    rtlName: "تعديل المشرف",
    icon: Person,
    component: CommentsUpdate,
    layout: "/admin",
    excludeFromSidebar: true,
  },

  {
    name: "User Managemant", // Name of the submenu
    rtlName: "إدارة المستخدمين", // RTL name of the submenu

    icon: PersonIcon, // Icon for the submenu (optional)
    layout: "/admin", // Parent layout for the submenu
    excludeFromSidebar: false,
    children: [
      // Array containing submenu items
      {
        path: "/permission-list",
        name: "Permissions",
        icon: PermissionIcon,
        rtlName: "المستخدمين",

        component: PermissionList,
      },
      {
        path: "/role-list",
        name: "Role",
        rtlName: "دور",
        icon: PersonIcon,
        component: RoleList,
      },
      {
        path: "/user-list",
        name: "Users",
        rtlName: "المستخدمين",
        icon: GroupIcon,
        component: AdminList,
      },
      // Add more submenu items as needed
    ],
  },

  {
    path: "/posts",
    name: "Posts",
    rtlName: "لوحة القيادة",
    icon: DescriptionIcon,
    component: Posts,
    layout: "/admin",
    excludeFromSidebar: false,
  },
  {
    path: "/poll",
    name: "Poll",
    rtlName: "لوحة القيادة",
    icon: PollIcon,
    component: PollList,
    layout: "/admin",
    excludeFromSidebar: false,
  },
  {
    path: "/advertise",
    name: "Advertise",
    rtlName: "إعلان",
    icon: LocalOfferIcon,
    component: Advertise,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/advertiseList",
    name: "Advertisement",
    rtlName: "إعلان",
    icon: LocalOfferIcon,
    component: AdvertiseList,
    layout: "/admin",
    excludeFromSidebar: false,
  },
  {
    path: "/group-list",
    name: "Group",
    rtlName: "مجموعة",
    icon: PeopleIcon,
    component: GroupList,
    layout: "/admin",
    excludeFromSidebar: false,
  },
  /* {
    path: "/review-list",
    name: "Review",
    rtlName: "مراجعة",
    icon: StarIcon,
    component: ReviewList,
    layout: "/admin",
    excludeFromSidebar: false,
  }, */
  {
    path: "/create-group/:id",
    name: "",
    rtlName: "عرض الدور",
    icon: VisibilityIcon,
    component: CreateGroup,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/edit-group/:id",
    name: "",
    rtlName: "تعديل الدور",
    icon: EditIcon,
    component: EditGroup,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/edit-review/:id",
    name: "",
    rtlName: "تعديل المراجعة",
    icon: EditIcon,
    component: EditReview,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  {
    path: "/review-list/:id",
    name: "",
    rtlName: "مراجعة",
    icon: StarIcon,
    component: ReviewList,
    layout: "/admin",
    excludeFromSidebar: true,
  },
  /* {
    path: "/review-list",
    name: "Review",
    rtlName: "مراجعة",
    icon: StarIcon,
    component: ReviewList,
    layout: "/admin",
    excludeFromSidebar: false,
  }, */
  // {
  //   path: '/table',
  //   name: 'Table List',
  //   rtlName: 'قائمة الجدول',
  //   icon: 'content_paste',
  //   component: TableList,
  //   layout: '/admin',
  //   excludeFromSidebar: false,
  // },
  // {
  //   path: '/typography',
  //   name: 'Typography',
  //   rtlName: 'طباعة',
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: '/admin',
  //   excludeFromSidebar: false,
  // },
  // {
  //   path: '/icons',
  //   name: 'Icons',
  //   rtlName: 'الرموز',
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: '/admin',
  //   excludeFromSidebar: false,
  // },
  // {
  //   path: '/maps',
  //   name: 'Maps',
  //   rtlName: 'خرائط',
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: '/admin',
  //   excludeFromSidebar: false,
  // },
  // {
  //   path: '/notifications',
  //   name: 'Notifications',
  //   rtlName: 'إخطارات',
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: '/admin',
  //   excludeFromSidebar: false,
  // },
  // {
  //   path: '/rtl-page',
  //   name: 'RTL Support',
  //   rtlName: 'پشتیبانی از راست به چپ',
  //   icon: Language,
  //   component: RTLPage,
  //   layout: '/rtl',
  //   excludeFromSidebar: false,
  // },
  // {
  //   path: '/upgrade-to-pro',
  //   name: 'Upgrade To PRO',
  //   rtlName: 'التطور للاحترافية',
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  //   layout: '/admin',
  //   excludeFromSidebar: false,
  // },
];

export default dashboardRoutes;

import React, { useEffect, useState } from "react";
// @material-ui/core components

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
//import { useToasts } from 'react-toast-notifications';

import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Loader from "components/Loader";
import { editCommentStyles } from "./CommentsStyle";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { FormControlLabel } from "@material-ui/core";
import { usePostStore } from "store/posts/post-store";
import { getCommentById } from "store/posts/post-action";
import { commentUpdateInput } from "shared/field-data";
import { updateCommentAction } from "store/posts/post-action";

export default function CommentsUpdate() {
  //make a add yupResolver
  const { id } = useParams();

  const commonClasses = editCommentStyles();
  const { commentDetail, loading } = usePostStore();
  // const { addToast } = useToasts();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();
  const { addToast } = useToasts();
  //   const [statusValue, setStatusValue] = useState(commentDetail?.status);
  //   const [isPublicValue, setIsPublicValue] = useState(!!commentDetail?.isPublic);

  const [commentData, setcommentData] = useState({
    content: "",
    status: "",
    isPublicValue: "",
    // Initialize image as null
  });
  const history = useHistory();
  useEffect(() => {
    getCommentById(addToast, id);
  }, []);
  //   React.useEffect(() => {
  //     // Set values for new fields
  //     if (commentDetail) {
  //       setValue('content', commentDetail?.content);
  //       setValue('status', commentDetail?.status); // Set default value to empty string if status is undefined
  //       setValue('isPublic', !!commentDetail.isPublic); // Convert to boolean
  //       // setValue('isApproved', !!commentDetail.isApproved);

  //       // Set state variables
  //       setStatusValue(commentDetail?.status);
  //       setIsPublicValue(!!commentDetail?.isPublic);
  //       setIsApprovedValue(!!commentDetail?.isApproved);

  //       console.log(commentDetail, 'commentDetail');
  //     }
  //   }, [setValue, commentDetail]);
  useEffect(() => {
    if (commentDetail) {
      setValue("content", commentDetail?.content);
      setValue("status", commentDetail?.status); // Set default value to empty string if status is undefined
      setValue("isPublic", !!commentDetail.isPublic);
      setcommentData({
        content: commentDetail.content || "",
        status: commentDetail?.status || "",
        isPublic: !!commentDetail?.isPublic,
      });
    }
  }, [commentDetail]);
  const onSubmit = (data) => {
    console.log(data);
    data.status = data?.status ? "Approved" : "Disapproved";
    // Update new fields
    commentDetail.content = data?.content;
    commentDetail.status = data?.status ? "Approved" : "Disapproved";
    commentDetail.isPublic = data?.isPublic;
    // commentDetail.isApproved = data?.isApproved;

    const postId = commentDetail?.posts[0]?._id;
    updateCommentAction(data, commentDetail?._id, postId, addToast, history); // Handle form submission here
  };
  const handleCancel = () => {
    const postId = commentDetail?.posts[0]?._id;
    history.push(`/admin/view-post/${postId}`);
  };
  //   const handleOnChange = (e) => {
  //     setValue('status', e.target.value);
  //     setStatusValue(e.target.value);
  //   };
  const handleOnChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setcommentData({
      ...commentData,
      [name]: newValue,
    });
  };
  const handleStausOnChange = (e) => {
    const { name, checked } = e.target;
    const newValue = checked ? "Approved" : "Disapproved";
    console.log("newValue", newValue);
    setcommentData({
      ...commentData,
      [name]: newValue,
    });
  };

  return (
    <div>
      {loading ? <Loader /> : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardHeader color="primary">
                <h4 className={commonClasses.cardTitleWhite}>Comment</h4>
                <p className={commonClasses.cardCategoryWhite}>
                  {/* Complete your profile */}
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {commentUpdateInput?.map((input) => (
                    <>
                      {input.name === "status" ? (
                        // <FormControl key={input.name} fullWidth>
                        //   <InputLabel>{input.placeholder}</InputLabel>
                        //   <Select
                        //     {...register(input.name)}
                        //     name={input.name} // Make sure the name attribute matches the field name
                        //     value={commentData?.status}
                        //     onChange={handleOnChange}
                        //   >
                        //     <MenuItem value="Approved">Approved</MenuItem>
                        //     <MenuItem value="Pending">Pending</MenuItem>
                        //     <MenuItem value="Disapproved">Disapproved</MenuItem>
                        //   </Select>
                        //</FormControl>
                        <FormControlLabel
                          key={input.name}
                          control={
                            <Switch
                              {...register(input.name)}
                              checked={
                                commentData?.status === "Approved"
                                  ? true
                                  : false
                              }
                              onChange={handleStausOnChange}
                            />
                          }
                          label={input.placeholder}
                        />
                      ) : input.name === "isPublic" ||
                        input.name === "isApproved" ? (
                        <FormControlLabel
                          key={input.name}
                          control={
                            <Switch
                              {...register(input.name)}
                              checked={commentData?.isPublic || false}
                              onChange={handleOnChange}
                            />
                          }
                          label={input.placeholder}
                        />
                      ) : (
                        <div>{commentData?.content}</div>
                        // <CustomInput
                        //   key={input.name}
                        //   id={input.name}
                        //   value={commentData?.content}
                        //   onChange={handleOnChange}
                        //   formControlProps={{ fullWidth: true }}
                        //   inputProps={{
                        //     type: input.type,
                        //     ...register(input.name),
                        //   }}
                        // />
                      )}
                      {errors[input.name] && (
                        <span
                          className="error-message"
                          style={{ color: "red" }}
                        >
                          {errors[input.name].message}
                        </span>
                      )}
                    </>
                  ))}
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  type="submit"
                  disabled={loading}
                  className="btn-theme"
                >
                  Update
                </Button>
                <Button
                  color="primary"
                  onClick={handleCancel}
                  // disabled={loading}
                  className="btn-theme"
                >
                  Cancel
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

// import * as React from 'react';
// import ErrorMessages from 'shared/error-message';
// import { ResponseStatus } from 'enums';

const fetcher = async (
  url,
  showError = true,
  addToast,
  method = 'GET',
  body
) => {
  const options = {
    method: method,

    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  // eslint-disable-next-line no-undef
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (typeof window !== 'undefined') {
    if (localStorage.accessToken) {
      options.headers['Authorization'] = `Bearer ${localStorage.accessToken}`;
    }
  }

  try {
    console.log(baseUrl + url, options, 'option');
    const response = await fetch(baseUrl + url, options);
    console.log('Response:', response);
    const json = await response.json();
    console.log('json:', json);

    if (response.ok) {
      return { data: json };
    } else {
      console.log(response, 'response');
      // Handle different status codes here
      switch (json.statusCode) {
        case 401:
          // alert(response.message);
          addToast(json.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          break;
        case 400:
          // alert(response.message);
          addToast(json.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          break;
        case 500:
          addToast('Internal Server Error', {
            appearance: 'error',
            autoDismiss: true,
          });
          break;
        // Add more cases for other status codes as needed
        default:
          addToast('Something went wrong!', {
            appearance: 'error',
            autoDismiss: true,
          });
          break;
      }
      //  throw new Error(json || "Something went wrong!");
    }
  } catch (e) {
    console.log(e, 'errorasdasfsfsdfsdf');
    if (showError) {
      // Handle error display or logging here
      console.error('Error:', e);
      // Rethrow the error to propagate it up
      throw e;
    } else {
      // If showError is false, just return null or any other value indicating failure
      return null;
    }
  }
};
export const fetcherSWR = async (url, showError = false, addToast, body) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(body),
  };
  if (typeof window !== 'undefined') {
    if (localStorage?.accessToken) {
      options.headers['Authorization'] = `Bearer ${localStorage?.accessToken}`;
    }
  }

  // eslint-disable-next-line no-undef
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let response = null;
  try {
    response = await fetch(baseUrl + url, options);
    const json = await response.json();
    if (response.ok) {
      return { data: json };
    } else {
      console.log(response, 'response');
      // Handle different status codes here
      switch (json.statusCode) {
        case 401:
          // alert(response.message);
          addToast(json.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          break;
        case 500:
          addToast('Internal Server Error', {
            appearance: 'error',
            autoDismiss: true,
          });
          break;
        // Add more cases for other status codes as needed
        default:
          addToast('Something went wrong!', {
            appearance: 'error',
            autoDismiss: true,
          });
          break;
      }
      //  throw new Error(json || "Something went wrong!");
    }
  } catch (e) {
    if (showError) {
      // Handle error display or logging here
      console.error('Error:', e);
      // Rethrow the error to propagate it up
      throw e;
    } else {
      // If showError is false, just return null or any other value indicating failure
      return null;
    }
  }
};
const api = {
  get(url, showError = false, addToast) {
    return fetcher(url, showError, addToast);
  },
  post(url, data, addToast, showError = true) {
    return fetcher(url, showError, addToast, 'POST', data);
  },
  put(url, data, addToast, showError = true) {
    return fetcher(url, showError, addToast, 'PUT', data);
  },
  delete(url, data, addToast, showError = true) {
    return fetcher(url, showError, addToast, 'DELETE', data);
  },
  patch(url, data, addToast, showError = true) {
    return fetcher(url, showError, addToast, 'PATCH', data);
  },
  patchUpdate(url, data, addToast, showError = true) {
    return fetcher(url, showError, addToast, 'PATCH');
  },
};
export default api;

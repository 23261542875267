/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';

// core components
import Admin from 'layouts/Admin.js';
import RTL from 'layouts/RTL.js';
import { ToastProvider } from 'react-toast-notifications';


import 'assets/css/material-dashboard-react.css?v=1.10.0';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/style.css';

import Login from 'views/Login';
import ForgotPassword from 'views/ForgotPassword';
import ResetPassword from 'views/ForgotPassword/ResetPassword';
import { PublicRoute } from 'HOCRoute';
import { PrivateRoute } from 'HOCRoute';

ReactDOM.render(
  <BrowserRouter>
    <ToastProvider>
      <Switch>
        <PublicRoute exact path='/login' component={Login} />
        <PublicRoute exact path='/forgot-password' component={ForgotPassword} />
        <PublicRoute exact path='/reset-password' component={ResetPassword} />
        <PublicRoute exact path='/rtl' component={RTL} />

        <PrivateRoute path='/admin' component={Admin} />

        {/* Redirect to admin dashboard by default */}
        <Redirect from='/' to='/admin/dashboard' />
      </Switch>
    </ToastProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

import React from 'react';
// react plugin for creating charts

import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.newClassName, 'coming-soon')}>
      <h1>Coming Soon</h1>
    </div>
  );
}

import React from 'react';

import loader from '../../assets/gif/loader.gif'; ///loader.gif'; // replace with the actual path to the loader gif
// import { Image } from '@material-ui/icons';

const Loader = () => (
  <div
    style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9999,
    }}
  >
    {/* <Image src={loader} alt='My animated GIF' width={100} height={100} /> */}
    <img
      src={loader}
      alt='My animated GIF'
      width={100}
      height={100}
      style={{ filter: 'hue-rotate(180deg) brightness(150%) saturate(150%)' }} // Change the theme color using CSS filter
    />
  </div>
);

export default Loader;

import React, { useEffect, useState } from 'react';
// @material-ui/core components
// import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { useToasts } from 'react-toast-notifications';
import Pagination from 'components/CustomPagination/CustomPagination';
import { getRoleList } from 'store/role/role-action';
import { useRoleStore } from 'store/role/role-store';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteRoles } from 'store/role/role-action';
import TextField from '@material-ui/core/TextField';
import { searchRoleName } from 'store/role/role-action';
import { Button } from '@material-ui/core';
import { useRoleListStyles } from './RoleListStyle';
import { useHistory } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import { activeInActiveRole } from 'store/role/role-action';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Loader from 'components/Loader';

export default function RoleList() {
  const { addToast } = useToasts();
  const commonClasses = useRoleListStyles();

  const { roleList, totalCount, loadingDeleteRole, loading } = useRoleStore();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  const history = useHistory();

  const handleClick = () => {
    // Redirect to the next page
    history.push('/admin/new-role');
  };
  const handleSelectAll = () => {
    setSelectedRows(roleList.map((role) => role._id));
  };

  const handleDeselectAll = () => {
    setSelectedRows([]);
  };

  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      const selectedRoleId = { roleIds: selectedRows };
      deleteRoles(selectedRoleId, addToast);
    } else {
      addToast('Please select a role to delete', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  const handleCheckboxChange = (event, roleId) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, roleId]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== roleId)
      );
    }
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    searchRoleName(addToast, event.target.value);
    // Add your search input logic here
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };
  //make a handle delete function

  useEffect(() => {
    getRoleList(addToast, currentPage + 1, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const handleDelete = (roleId) => {
    const selectedRoleId = { roleIds: [roleId] };
    deleteRoles(selectedRoleId, addToast);
    // Add your delete logic here
  };
  const handleToggle = (role_Id) => {
    activeInActiveRole(role_Id, addToast);
  };
  const handleView = (id) => {
    console.log(id);
    // getRoleById(addToast, id, history, false);
    history.push(`/admin/view-role/${id}`);
  };
  const handleEdit = (id) => {
    console.log(id);
    history.push(`/admin/edit-role/${id}`);
    //getRoleById(addToast, id, history, true);
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleClick}
            // className={commonClasses.buttonLeft}
            style={{ display: 'inline-block', width: 'auto' }}
          >
            Add new role
          </Button>
          <Card>
            <CardHeader color='primary'>
              <h4 className={commonClasses.cardTitleWhite}>Role List</h4>
              <p className={commonClasses.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <div className={commonClasses.buttonstyle}>
                <TextField
                  label='Search'
                  variant='outlined'
                  value={searchValue}
                  onChange={handleSearchChange}
                  className={commonClasses.buttonRight}
                />{' '}
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSelectAll}
                  className={commonClasses.buttonRight}
                  disabled={!Array.isArray(roleList) || roleList.length === 0}
                >
                  Select All
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleDeselectAll}
                  className={commonClasses.buttonRight}
                  disabled={!Array.isArray(roleList) || roleList.length === 0}
                >
                  Deselect All
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={
                    loadingDeleteRole ||
                    !Array.isArray(roleList) ||
                    roleList.length === 0
                  }
                  onClick={handleDeleteSelected}
                >
                  Delete Selected
                </Button>
              </div>

              {Array.isArray(roleList) && roleList.length > 0 ? (
                <Table
                  tableHeaderColor='primary'
                  tableHead={['', 'Title', 'Permissions', 'Actions']}
                  tableData={(Array.isArray(roleList) ? roleList : []).map(
                    (role) => [
                      <Checkbox
                        key={role._id}
                        checked={selectedRows.includes(role._id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, role._id)
                        }
                      />, // Add key prop for each element in the array
                      role?.name,
                      role?.permissions.map((permission, index) => (
                        <span
                          className={`${commonClasses.permissionName} custom-permission`}
                          key={permission._id}
                          style={{
                            marginRight:
                              index !== role.permissions.length - 1
                                ? '10px'
                                : '0',
                          }}
                        >
                          {permission?.name}

                          {/* Add condition to display comma only if it's not the last item */}
                        </span>
                      )),
                      <div
                        key={`role-${role._id}`}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <IconButton
                          key={`view-${role._id}`}
                          onClick={() => handleView(role._id)}
                        >
                          {/* Add view icon */}
                          <VisibilityIcon />
                        </IconButton>

                        <IconButton
                          key={`edit-${role._id}`}
                          onClick={() => handleEdit(role._id)}
                        >
                          {/* Add edit icon */}
                          <EditIcon />
                        </IconButton>
                        <Switch
                          key={`switch-${role._id}`}
                          checked={role?.isActive}
                          onChange={() => handleToggle(role._id)}
                          name='checkedA'
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />

                        <IconButton
                          key={`delete-${role._id}`}
                          onClick={() => handleDelete(role._id)}
                        >
                          {/* Add delete column */}
                          <DeleteIcon />
                        </IconButton>
                      </div>,
                    ]
                  )}
                />
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <p>Data not found</p>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Pagination
        count={Math.ceil(totalCount)}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
}

import ROUTES from 'utilities/api-routes';
import api from 'services/api';
import { usePollStore } from './poll-store';

export const getPollList = async (
  addToast,
  page,
  limit,
  pollType,
  status,
  startDate,
  endDate,
  pollState
) => {
  const { setState } = usePollStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getPollListApi(
        page,
        limit,
        pollType,
        status,
        startDate,
        endDate,
        pollState
      ),
      false,
      addToast
    );
    if (result?.polls) {
      return setState({
        pollList: result?.polls || [],
        totalCount: result?.totalCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        pollList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      pollList: [],
      totalCount: 0,
      loading: false,
      error: e.message,
    });
  }
};
export const addNewPollAction = async (data, addToast, history) => {
  const { setState } = usePollStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.post(
      ROUTES.addNewPoll(),
      reqData,
      addToast
    );
    // if (result?.success) {

    if (result) {
      setState({
        loading: false,
      });
      addToast(result?.message, { appearance: 'success', autoDismiss: true });
      history.replace('/admin/poll');
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: 'error', autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    addToast(e?.message, { appearance: 'error', autoDismiss: true });
  }
};
export const deletePoll = async (id, addToast) => {
  const { setState } = usePollStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.delete(
      ROUTES.deletePollApi(),
      id,
      addToast
    );
    if (result?.success) {
      addToast(result?.message, { appearance: 'success', autoDismiss: true });
      getPollList(addToast, 1, 5, 'All', 'All', null, null, 'Running');

      return setState({
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        pollList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      pollList: [],
      totalCount: 0,
      loading: false,
      error: e.message,
    });
  }
};
export const getPollDetail = async (id, addToast) => {
  const { setState } = usePollStore;
  setState({
    loading: true,
  });
  try {
    const { data: result } = await api.get(
      ROUTES.getPollById(id),
      false,
      addToast
    );
    if (result) {
      setState({
        pollDetail: result || {},

        pollOptionDetail: result?.options || [],
        isModalOpen: true,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      setState({
        pollOptionDetail: [],
        pollDetail: {},

        isModalOpen: false,
        loading: false,
      });
    }
  } catch (e) {
    return setState({
      pollOptionDetail: [],
      pollDetail: {},

      isModalOpen: false,

      loading: false,
      error: e.messages,
    });
  }
};
export const updatePollAction = async (data, id, addToast, history) => {
  const { setState } = usePollStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.put(
      ROUTES.updatePoll(id),
      reqData,
      addToast
    );
    if (result?.success) {
      setState({
        loading: false,
      });
      addToast(result?.message, { appearance: 'success', autoDismiss: true });
      history.replace('/admin/poll');
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: 'error', autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    //addToast(e?.message, { appearance: 'error', autoDismiss: true });
  }
};
export const getPollReportAction = async (id, addToast) => {
  const { setState } = usePollStore;
  setState({
    downloadLoading: true,
  });
  try {
    const { data: result } = await api.get(
      ROUTES.getPollReport(id),
      false,
      addToast
    );
    if (result?.csvFileUrl && result.csvFileUrl !== '') {
      setState({
        downloadLoading: false,
        csvFileUrl: result?.csvFileUrl,
      });
      const link = document.createElement('a');
      link.href = result?.csvFileUrl;
      link.rel = 'noopener noreferrer';
      link.download = 'poll_report.csv'; // Set the desired file name

      // Programmatically trigger the download
      link.click();
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      setState({
        csvFileUrl: '',

        downloadLoading: false,
      });
    }
  } catch (e) {
    return setState({
      downloadLoading: false,
      csvFileUrl: '',

      error: e.message,
    });
  }
};
export const activeInActivePoll = async (id, addToast) => {
  const { setState } = usePollStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.patchUpdate(
      ROUTES.getPollStatus(id),
      false,
      addToast
    );
    if (result) {
      addToast(result?.message, { appearance: 'success', autoDismiss: true });
      getPollList(addToast, 1, 5, 'All', 'All', null, null, 'Running');

      return setState({
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: 'error', autoDismiss: true });

      return setState({
        roleList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      roleList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};

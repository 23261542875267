import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { forgotPasswordAction } from 'store/admin/admin-action';
import { adminForgotSchema } from 'validation/admin';
import CustomInput from 'components/CustomInput/CustomInput';
import { useAdminStore } from 'store/admin/admin-store';
import { useToasts } from 'react-toast-notifications';
import { loginUsingForgot } from 'shared/field-data';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Button from 'components/CustomButtons/Button.js';
import Loader from 'components/Loader';

const ForgotPassword = () => {
  const { loading } = useAdminStore();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(adminForgotSchema),
  });

  const onSubmit = (data) => {
    forgotPasswordAction(data, addToast);
  };

  return (
    <>
      {loading ? <Loader /> : null}

      <GridContainer
        justify='center'
        style={{ height: '100vh', alignItems: 'center' }}
      >
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader
              color='primary'
              style={{ height: '50px', textAlign: 'left' }}
            >
              <h2 style={{ fontSize: 'medium' }}>Forgot Password</h2>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                {loginUsingForgot.map((input) => (
                  <CustomInput
                    key={input.name}
                    labelText={input.placeholder}
                    id={input.name}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: input.type,
                      ...register(input.name),
                    }}
                    error={!!errors[input.name]}
                    success={!errors[input.name]}
                  />
                ))}
                <GridContainer justify='center'>
                  <GridItem>
                    <div className='text-center'>
                    <Button
                      type='submit'
                      color='primary'
                      className='btn-theme mt-4 mx-auto'
                      disabled={loading}
                      style={{ width: '250px' }} // Adjust the height value as needed
                    >
                      Submit
                    </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter>{/* Add any footer content here */}</CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default ForgotPassword;

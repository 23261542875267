import React, { useEffect } from 'react';
import { Button, Card, CardContent, Chip, TextField } from '@material-ui/core';
import { useRoleStore } from 'store/role/role-store';
//import { useHistory } from 'react-router-dom';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { editRoleStyles } from './EditRoleStyle';
import { useForm } from 'react-hook-form';
import { updateRoleAction } from 'store/role/role-action';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getRoleById } from 'store/role/role-action';
import Loader from 'components/Loader';

const EditRole = () => {
  const { id } = useParams();

  const { roleDetail, loading } = useRoleStore();

  const history = useHistory();
  const { addToast } = useToasts();
  const commonClasses = editRoleStyles();
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: roleDetail,
  });
  const [permissions, setPermissions] = React.useState(roleDetail?.permissions);
  const [removedPermissionIds, setRemovedPermissionIds] = React.useState([]);
  useEffect(() => {
    getRoleById(addToast, id);
  }, []);
  React.useEffect(() => {
    setValue('name', roleDetail?.name);
    setValue('permissions', roleDetail?.permissions);
    setPermissions(roleDetail?.permissions); // Update local state when roleDetail changes
  }, [roleDetail, setValue]);

  const handleSave = (data) => {
    const updatedRole = {
      newName: data?.name,
      permissionIdsToAdd: [], // data.permissions.map((permission) => permission._id),
      permissionIdsToRemove: removedPermissionIds,
    };

    console.log('Updated Role', updatedRole);
    updateRoleAction(updatedRole, roleDetail?._id, addToast, history); // Handle form submission here
  };

  const handleDeletePermission = (id) => {
    const updatedPermissions = permissions?.filter(
      (permission) => permission?._id !== id
    );

    // Add the removed permission id to the removedPermissionIds state
    setRemovedPermissionIds([...removedPermissionIds, id]);

    setValue('permissions', updatedPermissions);
    setPermissions(updatedPermissions);
  };
  return (
    <GridContainer>
      {loading ? <Loader /> : null}

      <GridItem xs={12} sm={12} md={8}>
        <Card className='main-wrapper'>
          <CardHeader color='primary'>
            <h5 className={commonClasses.cardTitleWhite}>Edit Role</h5>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(handleSave)}>
              <GridContainer direction='column' spacing={2}>
                <GridItem>
                  <TextField
                    name='name'
                    variant='outlined'
                    {...register('name')}
                  />
                </GridItem>
                <GridItem>
                  {permissions?.length > 0 && (
                    <div style={{ }} className='mt-3'>
                      {permissions?.map((permission) => (
                        <Chip
                          key={permission._id}
                          label={permission.name}
                          onDelete={() =>
                            handleDeletePermission(permission._id)
                          }
                          style={{ border: '1px solid grey', margin: '3px' }} // Add border style here
                        />
                      ))}
                    </div>
                  )}
                </GridItem>
              </GridContainer>

              <Button variant='contained' color='primary' type='submit' className='mt-5'>
                Save
              </Button>
            </form>
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default EditRole;

import React from 'react';
import { TablePagination } from '@material-ui/core';
import PropTypes from 'prop-types';
export default function Pagination({
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPageOptions,
}) {
  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component='div'
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );
}
Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
};

// commonStyles.js
import { makeStyles } from '@material-ui/core/styles';

export const useRoleListStyles = makeStyles(() => ({
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  permissionName: {
    color: 'white',
    backgroundColor: 'blue',
    borderRadius: '5px',
    padding: '3px',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  buttonstyle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  buttonRight: { marginRight: '16px' },
  toggleButton: {
    color: 'red',
    '&.Mui-selected': {
      color: 'green',
    },
  },
}));

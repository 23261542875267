import { debounce } from '@material-ui/core';

export const getSupportedMimeTypes = () => {
  // Define your supported mime types here
  const possibleTypes = [
    'video/webm;codecs=vp9,opus',
    'video/webm;codecs=vp8,opus',
    'video/webm;codecs=h264,opus',
    'video/mp4;codecs=h264,aac',
    'video/mp4;codecs=avc1',
    'video/webm;codecs=av01,opus',
    'video/webm;codecs=av1,opus',
    // Add more supported types
  ];

  return possibleTypes.filter((mimeType) =>
    MediaRecorder.isTypeSupported(mimeType)
  );
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString(); // You can adjust the format as needed
};
export const delayedSearch = debounce(
  (addToast, searchTerm, currentPage, rowsPerPage, id, callback = () => {}) => {
    // Call your API function here with a delay of 2 seconds

    callback(addToast, searchTerm, currentPage, rowsPerPage, id);
    console.log(searchTerm);
  },
  2000
);
export const formatDateTime = (dateString) => {
  const options = {
    weekday: 'short', // Short day of the week
    month: 'short', // Short month name
    day: 'numeric', // Numeric day of the month
    year: 'numeric', // Full year
    hour: 'numeric', // Hour
    minute: 'numeric', // Minute
    hour12: true, // Use 12-hour format
  };

  return new Date(dateString).toLocaleString('en-US', options);
};

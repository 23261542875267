import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Paper, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Table from 'components/Table/Table.js';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative', // Added to make the close icon position absolute
    width: '50%', // Adjust the width of the modal as needed
    maxHeight: '80vh', // Added to limit the max height of the modal
    overflowY: 'auto', // Added to enable scrolling if content exceeds the max height
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const OptionsModal = ({ open, onClose, options }) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      aria-labelledby='options-modal-title'
      aria-describedby='options-modal-description'
    >
      <Paper className={classes.paper}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant='h5' gutterBottom>
          Options
        </Typography>

        {Array.isArray(options) && options.length > 0 ? (
          <Table
            tableHeaderColor='primary'
            tableHead={['Option', 'Votes']}
            tableData={(Array.isArray(options) ? options : []).map((option) => [
              option.option,
              option.votes,
            ])}
          />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p>Data not found</p>
          </div>
        )}
      </Paper>
    </Modal>
  );
};

OptionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string.isRequired,
      votes: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default OptionsModal;

import React from 'react';
// @material-ui/core components
import { yupResolver } from '@hookform/resolvers/yup';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import { useToasts } from 'react-toast-notifications';

import { useForm } from 'react-hook-form';
import { useAdminStore } from 'store/admin/admin-store';
import { newAdminStyles } from './AddNewAdminStyle';
import { adminInput } from 'shared/field-data';
import { adminInputSchema } from 'validation/admin';
import { addNewAdminAction } from 'store/admin/admin-action';
import { useHistory } from 'react-router-dom';

export default function AddNewAdmin() {
  //make a add yupResolver
  const history = useHistory();

  const { loading } = useAdminStore();
  const commonClasses = newAdminStyles();
  const { addToast } = useToasts();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(adminInputSchema),
  });
  const onSubmit = (data) => {
    console.log(data);
    addNewAdminAction(data, addToast, history); // Handle form submission here
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardHeader color='primary'>
                <h4 className={commonClasses.cardTitleWhite}>Create Admin</h4>
                <p className={commonClasses.cardCategoryWhite}>
                  {/* Complete your profile */}
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {adminInput?.map((input) => (
                    <>
                      <CustomInput
                        key={input.name}
                        labelText={input.placeholder}
                        id={input.name}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: input.type,
                          ...register(input.name), // Register the input field with react-hook-form
                        }}
                        error={!!errors[input.name]} // Set error state based on whether there are errors for this field
                        success={!errors[input.name]}

                        // Set success state based on whether there are no errors for this field
                      />{' '}
                      {errors[input.name] && (
                        <span
                          className='error-message'
                          style={{ color: 'red' }}
                        >
                          {errors[input.name].message}
                        </span>
                      )}
                    </>
                  ))}
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color='primary' className='btn-theme' type='submit' disabled={loading}>
                  Add
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React, { useEffect } from "react";
import { Button, Card, CardContent, Chip, TextField } from "@material-ui/core";
import { usePermissionStore } from "store/permissions/permission-store";
//import { useHistory } from 'react-router-dom';
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { EditPermissionStyle } from "./EditPermissionStyle";
import { useForm } from "react-hook-form";
import { updatePermissionAction } from "store/permissions/permission-action";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getPermissionById } from "store/permissions/permission-action";
import Loader from "components/Loader";

const EditPermission = () => {
  const { id } = useParams();
  const { permissionDetail, loading } = usePermissionStore();
  const history = useHistory();
  const { addToast } = useToasts();
  const commonClasses = EditPermissionStyle();
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: permissionDetail,
  });
  const [permissions, setPermissions] = React.useState(
    permissionDetail?.permissions
  );
  const [removedPermissionIds, setRemovedPermissionIds] = React.useState([]);
  useEffect(() => {
    getPermissionById(addToast, id);
  }, []);
  React.useEffect(() => {
    setValue("name", permissionDetail?.name);
    setValue("permissions", permissionDetail?.permissions);
    setPermissions(permissionDetail?.permissions);
  }, [permissionDetail, setValue]);

  const handleSave = (data) => {
    const updatedPermission = {
      name: data?.name,
    };
    console.log("Updated Permission", updatedPermission);

    updatePermissionAction(
      updatedPermission,

      permissionDetail?._id,
      addToast,
      history
    );
  };

  const handleDeletePermission = (id) => {
    const updatedPermissions = permissions?.filter(
      (permission) => permission?._id !== id
    );

    // Add the removed permission id to the removedPermissionIds state
    setRemovedPermissionIds([...removedPermissionIds, id]);

    setValue("permissions", updatedPermissions);
    setPermissions(updatedPermissions);
  };
  return (
    <GridContainer>
      {loading ? <Loader /> : null}

      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="primary">
            <h5 className={commonClasses.cardTitleWhite}>Edit Permission</h5>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(handleSave)}>
              <GridContainer direction="column" spacing={2}>
                <GridItem>
                  <TextField
                    label="Name"
                    name="name"
                    variant="outlined"
                    {...register("name")}
                  />
                </GridItem>
                <GridItem>
                  {permissions?.length > 0 && (
                    <div style={{ border: "1px solid grey", padding: "8px" }}>
                      {permissions?.map((permission) => (
                        <Chip
                          key={permission._id}
                          label={permission.name}
                          onDelete={() =>
                            handleDeletePermission(permission._id)
                          }
                          style={{ border: "1px solid grey", margin: "3px" }} // Add border style here
                        />
                      ))}
                    </div>
                  )}
                </GridItem>
              </GridContainer>

              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </form>
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default EditPermission;

export const loginInputs = [
  { type: 'email', name: 'email', placeholder: 'email' },
  { type: 'password', name: 'password', placeholder: 'password' },
];
export const loginUsingForgot = [
  { type: 'email', name: 'email', placeholder: 'email' },
];
export const resetPassword = [
  { type: 'newPassword', name: 'newPassword', placeholder: 'New Password' },
  {
    type: 'confirmPassword',
    name: 'confirmPassword',
    placeholder: 'Confirm Password',
  },
];

export const roleInputs = [
  { type: 'name', name: 'name', placeholder: 'Title' },
];

export const adminInput = [
  { type: 'username', name: 'username', placeholder: 'Name' },
  { type: 'email', name: 'email', placeholder: 'Email' },
  { type: 'password', name: 'password', placeholder: 'password' },
];
export const adminUpdateInput = [
  { type: 'username', name: 'username', placeholder: 'Name' },
  { type: 'email', name: 'email', placeholder: 'Email' },
];
export const permissionInputs = [
  { type: 'name', name: 'name', placeholder: 'Name' },
  {
    type: 'description',
    name: 'description',
    placeholder: 'Description',
  },
];
export const postUpdateInput = [
  { type: 'text', name: 'title', placeholder: 'Title' },
  { type: 'text', name: 'description', placeholder: 'Description' },
  { type: 'file', name: 'image', placeholder: 'Image' }, // Add image field
  { type: 'checkbox', name: 'isPinned', placeholder: 'Is Pinned' },
  { type: 'checkbox', name: 'isPublic', placeholder: 'Is Public' },
  { type: 'checkbox', name: 'isApproved', placeholder: 'Is Approved' },
  // ... other fields ...
];

//make a array create
export const commentUpdateInput = [
  { type: 'text', name: 'content', placeholder: 'Content' },
  { type: 'text', name: 'status', placeholder: 'Status' },
  { type: 'toggle', name: 'isPublic', placeholder: 'Is Public' },
  // { type: 'toggle', name: 'isApproved', placeholder: 'Is Approved' },
  // ... other fields ...
];
// export const inputPollData = [
//   { type: 'text', name: 'Title', placeholder: 'Title' },
//   { type: 'text', name: 'Description', placeholder: 'Description' },
//   { type: 'text', name: 'pollType', placeholder: 'Poll Type' },
//   { type: 'text', name: 'status', placeholder: 'Status' },
//   { type: 'text', name: 'options', placeholder: 'Options' },
// ];
export const inputPollData = [
  { type: 'text', name: 'Title', placeholder: 'Poll Title' },
  { type: 'text', name: 'Description', placeholder: 'Description' },
  {
    type: 'select',
    name: 'pollType',
    placeholder: 'Poll Type',
    options: [
      { value: 'Group', label: 'Group' },
      { value: 'All Members', label: 'All Members' },
    ],
  },
  {
    type: 'select',
    name: 'status',
    placeholder: 'Status',
    options: [
      { value: 'Active', label: 'Active' },
      { value: 'Inactive', label: 'Inactive' },
    ],
  },
  {
    type: 'text',
    name: 'groupId',
    placeholder: 'Group',
    visibleOn: 'pollType',
    value: 'Group',
  },
  { type: 'datepicker', name: 'startDate', placeholder: 'Start Date' },
  { type: 'datepicker', name: 'endDate', placeholder: 'End Date' },
  { type: 'number', name: 'numberOfOptions', placeholder: 'Number of Options' },
];

export const sortingOptions = ['a-z', 'z-a'];
export const sortby = ['desc', 'asce'];
export const pollStateList = ['Running', 'Upcoming', 'Completed'];

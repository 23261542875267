import { usePermissionStore } from "./permission-store";
import api from "services/api";
import ROUTES from "utilities/api-routes";

export const addNewPermissionAction = async (data, addToast, history) => {
  const { setState } = usePermissionStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.post(
      ROUTES.addNewPermission(),
      reqData,
      addToast
    );
    if (result?.success) {
      setState({
        loading: false,
      });
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      history.replace("/admin/permission-list");
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    addToast(e?.message, { appearance: "error", autoDismiss: true });
  }
};

export const getPermissionListAction = async (addToast, page, limit) => {
  const { setState } = usePermissionStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.permissionList(page, limit),
      false,
      addToast
    );
    if (result?.data) {
      return setState({
        permissionList: result?.data || [],
        totalCount: result?.totalCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        permissionList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      permissionList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};

export const SearchPermissionListAction = async (addToast, name) => {
  const { setState } = usePermissionStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.searchPermission(name),
      false,
      addToast
    );
    if (result?.permissions) {
      return setState({
        permissionList: result?.permissions || [],
        totalCount: result?.totalCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        permissionList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    addToast(e?.message, { appearance: "error", autoDismiss: true });

    return setState({
      permissionList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};
export const deletePermissions = async (permissionId, addToast) => {
  const { setState } = usePermissionStore;
  setState({
    loadingDeletePermission: true,
  });

  try {
    const { data: result } = await api.delete(
      ROUTES.deletePermission(),
      permissionId,
      addToast
    );
    if (result?.deletedCount) {
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      getPermissionListAction(addToast, 1, 5);
      return setState({
        loadingDeletePermission: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        permissionList: [],
        totalCount: 0,

        loadingDeletePermission: false,
      });
    }
  } catch (e) {
    return setState({
      permissionList: [],
      totalCount: 0,
      loadingDeletePermission: false,
      error: e.messages,
    });
  }
};
export const activeInActivePermission = async (permissionId, addToast) => {
  const { setState } = usePermissionStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.patchUpdate(
      ROUTES.activeInActivePermissionId(permissionId),
      false,
      addToast
    );
    if (result?.success) {
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      getPermissionListAction(addToast, 1, 5);
      return setState({
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        permissionList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      permissionList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};
export const updatePermissionAction = async (data, id, addToast, history) => {
  const { setState } = usePermissionStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.put(
      ROUTES.updatePermission(id),
      reqData,
      addToast
    );
    if (result) {
      setState({
        loading: false,
      });
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      history.replace("/admin/permission-list");
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    addToast(e?.message, { appearance: "error", autoDismiss: true });
  }
};
export const getPermissionById = async (addToast, id) => {
  const { setState } = usePermissionStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getPermissionByIdApi(id),
      false,
      addToast
    );
    if (result) {
      setState({
        permissionDetail: result || {},

        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      setState({
        permissionDetail: {},

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      permissionDetail: {},

      loading: false,
      error: e.messages,
    });
  }
};

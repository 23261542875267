const ROUTES = {
  login: () => "/api/admin/login",
  forgotPassword: () => "/api/admin/forgot-password",
  resetPassword: (token) => `/api/admin/reset-password?token=${token}`,
  addNewRole: () => "/api/create-role",
  getRoleListApi: (page, limit) =>
    "/api/roles?page=" + page + "&limit=" + limit,
  deleteToleById: (id) => `/api/${id}/delete-role`,
  searchRoleByName: (name) => `/api/search-role?query=${name}`,
  deleteSelectedId: () => "/api/delete-roles",
  activeInActiveRoleId: (id) => `/api/role/${id}/toggle-active`,
  getAdminListApi: (page, limit) =>
    "/api/admins?page=" + page + "&limit=" + limit,

  activeInActiveAdminId: (id) => `/api/admin/${id}/toggle-active`,
  searchAdminByName: (name) => `/api/search-admin?query=${name}`,
  getAdminByIdApi: (id) => `/api/admin/${id}`,
  addNewAdmin: () => "/api/create-admin",
  updateAdmin: (id) => `/api/update-admin/${id}`,
  deleteAdminSelectedId: () => "/api/delete-admin",
  getRoleByIdApi: (id) => `/api/role/${id}`,
  updateRole: (id) => `/api/${id}/modify-permissions`,
  updateStatus: () => `/api/update-status`,
  removePermissionIds: (id) => `/api/${id}/remove-permissions`,
  addNewPermission: () => "/api/create-permission",
  permissionList: (page, limit) =>
    "/api/permissions?page=" + page + "&limit=" + limit,
  searchPermission: (name) => `/api/search-permission?query=${name}`,
  deletePermission: () => `/api/delete-permissions`,
  activeInActivePermissionId: (id) => `/api/permission/${id}/toggle-active`,
  updatePermission: (id) => `/api/update-permission/${id}`,
  getPermissionByIdApi: (id) => `/api/permission/${id}`,
  getPostListApi: (page, limit, status, postType, sorting) =>
    "/api/post-list?page=" +
    page +
    "&limit=" +
    limit +
    "&status=" +
    status +
    "&type=" +
    postType +
    "&sortby=" +
    sorting,

  reviewList: (id, page, limit, sortby) =>
    `/api/${id}/ratings/all-review?page=${page}&limit=${limit}&sortby=${sortby}`,
  updateRatingStatus: () => `/api/update-rating-status`,
  groupList: (page, limit, sortby, grouptype, status) =>
    "/api/group-list?page=" +
    page +
    "&limit=" +
    limit +
    "&sortby=" +
    sortby +
    "&type=" +
    grouptype +
    "&status=" +
    status,
  deletePostApi: () => "/api/delete-posts",
  activeInActivePostId: (id) => `/api/post/toggle-approval/${id}`,
  getStatusApi: () => "/api/post-status",
  getGroupStatusApi: () => "/api/group-status",
  getPostByIdApi: (id) => `/api/post/${id}`,
  getPostTypeApi: () => "/api/post-type", //api/post-type
  getAllCommentsApi: (id, page, limit, status, sortby, startDate, endDate) => {
    let url = `/api/${id}/comments?page=${page}&limit=${limit}&status=${status}&sortby=${sortby}`;

    // Check if startDate is provided
    if (startDate) {
      url += `&startDate=${startDate}`;
    }

    // Check if endDate is provided
    if (endDate) {
      url += `&endDate=${endDate}`;
    }

    return url;
  },
  getGroupTypeApi: () => "/api/group-type",
  updatePost: (id) => `/api/update-post/${id}`,
  searchPost: (query, page, limit) =>
    `/api/search-post?query=${query}&page=${page}&limit=${limit}`,
  activeInActiveCommentId: (id) => `/api/comments/toggle-approval/${id}`,
  deleteCommentApi: () => "/api/delete-comments",
  getCommentByIdApi: (id) => `/api/comments/${id}/replies`,
  updateCommentApi: (id) => `/api/update-comment/${id}`,
  searchComment: (query, page, limit, id) =>
    `/api/${id}/search-comments?searchTerm=${query}&page=${page}&limit=${limit}`,

  allGroupList: () => "/api/sorted-group-list",

  deleteGroupApi: () => `/api/delete-groups`,
  searchGroup: (name, page, limit) =>
    `/api/search-group?query=${name}&page=${page}&limit=${limit}`,
  updateGroup: (id) => `/api/update-group/${id}`,
  viewGroup: (id) => `/api/group/${id}`,
  createGroup: () => `/api/create-group`,
  groupStatus: (id) => `/api/group/toggle-approval/${id}`,
  getGroupByIdApi: (id) => `/api/group/${id}`,
  addNewPoll: () => "/api/create-poll",
  createAdvertise: () => "/api/create-advertisement",
  sortedGroupListApi: () => "/api/sorted-group-list",

  reviewListById: (id) => `/api/rating/${id}`,
  advertiseList: (page, limit, type, status, startDate, endDate, sortby) => {
    let url = `/api/advertisement-list?type=${type}&status=${status}&page=${page}&limit=${limit}&sortby=${sortby}`;
    // Check if startDate is provided
    if (startDate) {
      url += `&startDate=${startDate}`;
    }

    // Check if endDate is provided
    if (endDate) {
      url += `&endDate=${endDate}`;
    }

    return url;
  },
  deleteAdvertise: () => `/api/delete-advertisement`,
  searchAdvertise: (query, page, limit) =>
    `/api/search-advertisement?query=${query}&page=${page}&limit=${limit}`,
  getPollTypeApi: () => "/api/poll-type",

  getPollTypeList: () => `/api/poll-type`,
  getPollSatatusList: () => `/api/poll-status`,
  deletePollApi: () => `/api/delete-polls`,
  getPollById: (id) => `/api/poll/${id}`,
  updatePoll: (id) => `/api/update-poll/${id}`,
  getPollReport: (id) => `/api/${id}/export`,
  getPollState: () => `/api/poll-state`, //api/poll-state
  getPollStatus: (id) => `/api/${id}/toggle-status`,

  getPollListApi: (
    page,
    limit,
    pollType,
    status,
    startDate,
    endDate,
    pollState
  ) => {
    let url = `/api/poll-list?page=${page}&limit=${limit}&pollType=${pollType}&status=${status}&PollState=${pollState}`;

    // Check if startDate is provided
    if (startDate) {
      url += `&startDate=${startDate}`;
    }

    // Check if endDate is provided
    if (endDate) {
      url += `&endDate=${endDate}`;
    }

    return url;
  },
};
export default ROUTES;

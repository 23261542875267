import React, { useEffect, useState } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Loader from 'components/Loader';
import { updatePostStyles } from './UpdateStyle';
import { getPostById } from 'store/posts/post-action';
import { usePostStore } from 'store/posts/post-store';
import { IconButton, InputLabel, Switch, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useToasts } from 'react-toast-notifications';
import { updatePostAction } from 'store/posts/post-action';
import { useHistory, useParams } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getSupportedMimeTypes } from 'helper';

export default function UpdatePost() {
  const { addToast } = useToasts();
  const { id } = useParams();
  const commonClasses = updatePostStyles();
  const { postDetail, loading } = usePostStore();
  const [postDetails, setPostDetails] = useState(postDetail);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const supportedMimeTypes = getSupportedMimeTypes();

  const history = useHistory();

  useEffect(() => {
    setPostDetails(postDetail);
  }, [postDetail?.FileUrl]);

  useEffect(() => {
    getPostById(addToast, id);
  }, []);

  useEffect(() => {
    // Set the editor state from API data when postDetail.description changes
    if (postDetail?.description) {
      console.log('postDetail?.description:', postDetail?.description);
      try {
        const contentState = {
          blocks: [
            {
              text: postDetail?.description || '',
              type: 'unstyled',
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
          ],
          entityMap: {},
        };
        const newEditorState = EditorState.createWithContent(
          convertFromRaw(contentState)
        );
        setEditorState(newEditorState);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }
  }, [postDetail.description]);
  const onSubmit = (e) => {
    e.preventDefault();

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    // Convert rawContentState to JSON string
    const rawContentStateJson = JSON.stringify(rawContentState);
    console.log('rawContentStateJson:', rawContentStateJson);
    const parsedContentState = JSON.parse(rawContentStateJson);

    // Get the text from the first block
    const descriptionText = parsedContentState?.blocks[0]?.text || '';

    console.log('Description Text:', descriptionText);
    const payload = {
      title: postDetails?.title,
      description: descriptionText, // rawContentStateJson?.blocks[0]?.text,
      groupId: postDetails?.groups[0]?._id,
      FileUrl: postDetails?.FileUrl,
      isPinned: postDetails?.isPinned,
      isPublic: postDetails?.isPublic,
      status: postDetails?.isApproved ? 'Approved' : 'Disapproved',
    };
    updatePostAction(payload, id, addToast, history);
  };

  // const handleImageRemove = (indexToRemove) => {
  //   const updatedImageList = [...postDetail.FileUrl];
  //   updatedImageList.splice(indexToRemove, 1);
  //   setPostDetails({ ...postDetail, image: updatedImageList });
  // };
  const handleImageRemove = (indexToRemove) => {
    console.log('Index to remove:', indexToRemove);
    const updatedFileUrlList = [...postDetails.FileUrl];
    updatedFileUrlList.splice(indexToRemove, 1);
    setPostDetails({ ...postDetails, FileUrl: updatedFileUrlList });
  };
  const handleCancel = () => {
    history.push(`/admin/posts`);
  };
  // const isImage = (url) => {
  //   console.log('url:', url);
  //   return /\.(jpeg|jpg|gif|png)$/.test(url);
  // };
  return (
    <div>
      {loading ? <Loader /> : null}
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <form onSubmit={onSubmit}>
            <Card>
              <CardHeader color='primary'>
                <h4 className={commonClasses.cardTitleWhite}>Edit Post</h4>
              </CardHeader>
              <CardBody>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={commonClasses.grideItem}
                >
                  <label htmlFor='title'>Title</label>

                  <CustomInput
                    id='title'
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: 'text',
                      value: postDetails['title'] || '',
                      onChange: (e) =>
                        setPostDetails({
                          ...postDetails,
                          title: e.target.value,
                        }),
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={commonClasses.grideItem}
                >
                  <InputLabel htmlFor='postType' className='mt-5'>
                    Post Type
                  </InputLabel>

                  <TextField
                    id='postType'
                    // className='mt-0 pt-0'
                    variant='outlined'
                    className={`${commonClasses.inputField} w-100`}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: 'text',
                      value: postDetails['type'] || '',
                      readOnly: true,
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={commonClasses.grideItem}
                >
                  <label htmlFor='description' className='mt-5 pb-3'>
                    Description
                  </label>
                  <div className={commonClasses.editorStyle}>
                    <Editor
                      editorState={editorState}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='wrapperClassName'
                      editorClassName='editorClassName'
                      onEditorStateChange={(editorState) =>
                        setEditorState(editorState)
                      }
                    />
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={`${commonClasses.grideItem}`}
                >
                  {postDetails?.FileUrl?.length > 0 && (
                    <label htmlFor='image' className='mt-4 mb-3 pt-2'>
                      Image
                    </label>
                  )}

                  <div className={commonClasses.selectStyle}>
                    {Array.isArray(postDetails?.FileUrl) &&
                      postDetails?.FileUrl?.map((url, index) => (
                        <div key={index}>
                          {postDetails?.type == 'Image' ? (
                            <img
                              key={index}
                              src={url}
                              alt={`Image ${index + 1}`}
                              className={commonClasses.imageStyle}
                            />
                          ) : postDetails?.type == 'Video' ? (
                            <video
                              key={index}
                              controls
                              className={commonClasses.videoStyle}
                            >
                              {/* <source src={url} type='video/mp4' />
                              Your browser does not support the video tag. */}
                              {supportedMimeTypes.map((mimeType, i) => (
                                <source key={i} src={url} type={mimeType} />
                              ))}
                            </video>
                          ) : null}
                          <IconButton
                            className='close-icon'
                            onClick={() => handleImageRemove(index)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      ))}
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={commonClasses.grideItem}
                >
                  <label htmlFor='isPinned'>Is Pinned</label>

                  <Switch
                    id='isPinned'
                    label='Is Pinned'
                    name='isPinned'
                    checked={postDetails['isPinned'] || false}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        isPinned: e.target.checked,
                      })
                    }
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={commonClasses.grideItem}
                >
                  <label htmlFor='isPublic'>Is Public</label>

                  <Switch
                    id='isPublic'
                    label='Is Public'
                    name='isPublic'
                    checked={postDetails['isPublic'] || false}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        isPublic: e.target.checked,
                      })
                    }
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={commonClasses.grideItem}
                >
                  <label htmlFor='isApproved'>Is Approved</label>

                  <Switch
                    id='isApproved'
                    label='Is Approved'
                    name='isApproved'
                    checked={postDetails?.status === 'Approved' ? true : false}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const newStatus = checked ? 'Approved' : 'Disapproved';
                      setPostDetails({
                        ...postDetails,
                        isApproved: checked,
                        status: newStatus,
                      });
                    }}
                  />
                </GridItem>
              </CardBody>
              <CardFooter className='justify-content-start'>
                <Button
                  color='primary'
                  type='submit'
                  disabled={loading}
                  variant='contained'
                  className='btn-theme w-auto px-4'
                >
                  Update
                </Button>
                <Button
                  color='primary'
                  onClick={handleCancel}
                  variant='contained'
                  className='btn-theme w-auto px-4'
                >
                  Cancel
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

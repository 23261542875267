import { create } from "zustand";

export const INTIAL_STATE = {
  modal: false,
  loading: false,
  loadingVerify: false,
  advertiseList: [],
  modalType: "",
  error: null,
  isForgotView: false,
  totalCount: 0,
  loadingDeleteAdvertise: false,
};

export const useAdvertiseStore = create(() => ({
  ...INTIAL_STATE,
}));

export const useLoading = () => {
  return useAdvertiseStore((s) => ({
    loading: s.loading,
    totalCount: s.totalCount,
    advertiseList: s.advertiseList,
    error: s.error,
    loadingDeleteAdvertise: s.loadingDeleteAdvertise,
  }));
};
export const search = () => {
  return useAdvertiseStore((s) => ({
    searchAdvertise: s.searchAdvertise,
  }));
};

export const deleteselectadvertise = () => {
  return useAdvertiseStore((s) => ({
    deleteAdvertise: s.deleteAdvertise,
    error: s.error,
  }));
};

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { useGroupStore } from "store/group/group-store";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getGroupById } from "store/group/group-action";
import { updateGroupAction } from "store/group/group-action";
import { CreateNewGroupAction } from "store/group/group-action";
import Loader from "components/Loader";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { groupValidationSchema } from "../../../validation/admin";
import { getGroupType } from "store/group/group-action";
import { getStatus } from "store/group/group-action";

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 170,
    },
  },
}));

const EditGroup = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { groupDetail, loading, groupTypeList } = useGroupStore(); // Fetch groupTypeList and statusList from the store
  /*   const { groupDetail, loading, groupTypeList, statusList } = useGroupStore(); */ console.log(
    groupDetail,
    "groupDetailgroupDetail"
  );
  const history = useHistory();
  const { addToast } = useToasts();
  const [imagePreview, setImagePreview] = useState(null);
  const [isCreateGroup, setIsCreateGroup] = useState(false);
  const [groupData, setGroupData] = useState({
    title: "",
    description: "",
    type: "", // Initialize groupType to an empty string
    //  status: false,
    image: null,
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    getGroupType(addToast);
    getStatus(addToast);
  }, []);
  /* 
  useEffect(() => {
    if (groupDetail) {
      setGroupData({
        title: groupDetail.title || "",
        description: groupDetail.description || "",
        groupType: groupDetail.isBroker ? "Broker" : "Basic",
        image: groupDetail.image || null,
        status: groupDetail.isApproved,
      });
      setImagePreview(groupDetail.image[0] ? groupDetail.image[0] : null);
    }
  }, [groupDetail]); */
  useEffect(() => {
    if (groupDetail) {
      setGroupData({
        title: groupDetail.title || "",
        description: groupDetail.description || "",
        type: groupDetail.type,
        image: groupDetail.image || null,
        //  status: groupDetail.status,
      });

      // Check if groupDetail.image is defined before accessing its first element
      if (groupDetail.image && groupDetail.image.length > 0) {
        setImagePreview(groupDetail.image[0]);
      } else {
        setImagePreview(null);
      }
    }
  }, [groupDetail]);

  useEffect(() => {
    getGroupById(addToast, id);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setGroupData({ ...groupData, [name]: newValue });
  };

  const isFileInstance = (obj) => {
    return obj instanceof File;
  };

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   const newValue = type === "checkbox" ? checked : value;

  //   // If the target is the image input, update the preview
  //   if (name === "image") {
  //     const file = e.target.files[0];
  //     console.log("Selected file:", file); // Debugging statement

  //     if (file) {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         console.log("FileReader result:", reader.result); // Debugging statement
  //         setImagePreview(reader.result);
  //       };
  //       reader.readAsDataURL(file);
  //     } else {
  //       setImagePreview(null);
  //     }
  //   }

  //   setGroupData({ ...groupData, [name]: newValue });
  // };
  const handleCreatenewgroup = async (redirect) => {
    try {
      await groupValidationSchema.validate(groupData, { abortEarly: false });
      let image = null;
      if (groupData.type === "Broker") {
        if (isFileInstance(groupData.image)) {
          image = await getBase64Image(groupData.image);
        } else {
          image = groupData.image;
        }
      }
      const adminData = JSON.parse(localStorage.getItem("admin"));
      const adminId = (adminData && adminData._id) || "";

      const requestPayload = {
        title: groupData.title,
        description: groupData.description,
        image: image,
        type: groupData.type,
        //    status: groupData.status,
        authorId: adminId,
      };

      CreateNewGroupAction(
        requestPayload,
        addToast,
        redirect ? history.goBack() : undefined
      );
    } catch (errors) {
      console.log(errors, "errors");
      let validationErrors = {};
      errors?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setFormErrors(validationErrors);
    }
  };

  const handleSave = async (redirect, buttonAction) => {
    try {
      await groupValidationSchema.validate(groupData, { abortEarly: false });
      let image = null;
      if (groupData.type === "Broker") {
        if (isFileInstance(groupData.image)) {
          image = await getBase64Image(groupData.image);
        } else {
          image = groupData.image;
        }
      }
      const requestPayload = {
        title: groupData.title,
        description: groupData.description,
        image: image,
        type: groupData.type,
        //    status: groupData.status,
      };

      updateGroupAction(
        requestPayload,
        groupDetail?._id,
        addToast,
        redirect ? history : undefined
      );
      if (buttonAction === "save&addnew") {
        setGroupData({
          title: "",
          description: "",
          type: "", // Reset groupType to an empty string
          //   status: false,
          image: null,
        });
        setImagePreview(null);
        setIsCreateGroup(true);
      } else {
        setIsCreateGroup(false);
      }
    } catch (errors) {
      console.log(errors, "errors");
      let validationErrors = {};
      errors?.inner?.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setFormErrors(validationErrors);
    }
  };
  console.log(groupDetail, "groupDetailsss");

  const getBase64Image = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleCancel = () => {
    history.goBack();
  };
  const handleRemoveImage = () => {
    setImagePreview(null);
    setGroupData({ ...groupData, image: null });
  };
  console.log(groupData, "groupDataaaaaaaaaaaaaaaa");
  return (
    <>
      {loading ? <Loader /> : null}
      <Card className="main-wrapper">
        <CardContent>
          <CardHeader color="primary">
            <h4>Add/Edit Group Form</h4>
          </CardHeader>
        </CardContent>
        <CardBody>
          <form>
            <div style={{ marginBottom: "2rem" }}>
              <InputLabel htmlFor="group-name">Group Name</InputLabel>
              <br />
              <TextField
                id="title"
                name="title"
                variant="outlined"
                value={groupData.title}
                onChange={handleChange}
                error={!!formErrors["title"]}
                helperText={formErrors["title"]}
                className={`${classes.inputField} w-100`}
              />
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <InputLabel htmlFor="description">Description</InputLabel>
              <br />
              <TextField
                id="description"
                name="description"
                variant="outlined"
                value={groupData.description}
                onChange={handleChange}
                error={!!formErrors["description"]}
                helperText={formErrors["description"]}
                className={`${classes.inputField} w-100`}
              />
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <InputLabel htmlFor="group-type">Group Type</InputLabel>
              <br />
              <FormControl
                variant="outlined"
                className={`${classes.inputField} w-100`}
              >
                <Select
                  id="group-type"
                  name="type"
                  value={groupData.type}
                  onChange={handleChange}
                >
                  {groupTypeList.map((type) => {
                    return (
                      type !== "All" && (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      )
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            {groupData.type === "Broker" && (
              <div style={{ marginBottom: "2rem" }}>
                <InputLabel htmlFor="image">Upload Image</InputLabel>
                <br />
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept="image/*"
                  onChange={(e) => {
                    setGroupData({
                      ...groupData,
                      image: e?.target?.files[0],
                    });
                    if (e?.target?.files[0]) {
                      setImagePreview(URL.createObjectURL(e?.target?.files[0]));
                    } else {
                      setImagePreview(null);
                    }

                    // If the target is the image input, update the preview
                  }}
                  className={classes.inputField}
                />
              </div>
            )}
            {groupData.type === "Broker" && imagePreview && (
              <div
                style={{
                  marginBottom: "2rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <InputLabel>Image Preview</InputLabel>
                  <br />
                  <img
                    src={imagePreview}
                    alt="Image Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      marginTop: "0.5rem",
                      marginRight: "1rem",
                    }}
                  />
                </div>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleRemoveImage}
                >
                  Remove
                </Button>
              </div>
            )}
            {/*         <div style={{ marginBottom: "1rem" }}>
              <InputLabel>Status</InputLabel>
              <br />
              <FormControl
                variant="outlined"
                className={`${classes.inputField} w-100`}
              >
                <Select
                  id="group-status"
                  name="status"
                  value={groupData.status}
                  onChange={handleChange}
                >
                  {statusList.map((status) => {
                    return (
                      status !== "All" && (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      )
                    );
                  })}
                </Select>
              </FormControl>
            </div>
 */}
            {isCreateGroup ? (
              <div style={{ marginTop: "1rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleCreatenewgroup(true, "save&addnew")}
                  style={{ marginLeft: "1rem" }}
                >
                  Save & Add New
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                  style={{ marginLeft: "1rem" }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div style={{ marginTop: "1rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSave(false, "save&stay")}
                >
                  Save & Stay
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSave(false, "save&addnew")}
                  style={{ marginLeft: "1rem" }}
                >
                  Save & Add New
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSave(true, "save&back")}
                  style={{ marginLeft: "1rem" }}
                >
                  Save & Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCancel}
                  style={{ marginLeft: "1rem" }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </form>
        </CardBody>
      </Card>
    </>
  );
};

export default EditGroup;

import api from "services/api";
import ROUTES from "utilities/api-routes";
import { useRoleStore } from "./role-store";

export const getRoleList = async (addToast, page, limit) => {
  const { setState } = useRoleStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getRoleListApi(page, limit),
      false,
      addToast
    );
    if (result?.data) {
      return setState({
        roleList: result?.data || [],
        totalCount: result?.totalCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        roleList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      roleList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};

export const searchRoleName = async (addToast, name) => {
  const { setState } = useRoleStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.searchRoleByName(name),
      false,
      addToast
    );
    if (result?.roles) {
      return setState({
        roleList: result?.roles || [],
        totalCount: result?.totalCount,
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        roleList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    addToast(e?.message, { appearance: "error", autoDismiss: true });

    return setState({
      roleList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};

export const deleteRoles = async (roleId, addToast) => {
  const { setState } = useRoleStore;
  setState({
    loadingDeleteRole: true,
  });

  try {
    const { data: result } = await api.delete(
      ROUTES.deleteSelectedId(),
      roleId,
      addToast
    );
    if (result?.deletedCount) {
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      getRoleList(addToast, 1, 5);
      return setState({
        loadingDeleteRole: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        roleList: [],
        totalCount: 0,

        loadingDeleteRole: false,
      });
    }
  } catch (e) {
    return setState({
      roleList: [],
      totalCount: 0,
      loadingDeleteRole: false,
      error: e.messages,
    });
  }
};
export const activeInActiveRole = async (roleId, addToast) => {
  const { setState } = useRoleStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.patchUpdate(
      ROUTES.activeInActiveRoleId(roleId),
      false,
      addToast
    );
    if (result?.success) {
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      getRoleList(addToast, 1, 5);
      return setState({
        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        roleList: [],
        totalCount: 0,

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      roleList: [],
      totalCount: 0,
      loading: false,
      error: e.messages,
    });
  }
};
export const addNewRoleAction = async (data, addToast, history) => {
  const { setState } = useRoleStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.post(
      ROUTES.addNewRole(),
      reqData,
      addToast
    );
    if (result?.success) {
      setState({
        loading: false,
      });
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      history.replace("/admin/role-list");
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    addToast(e?.message, { appearance: "error", autoDismiss: true });
  }
};
export const getRoleById = async (addToast, id) => {
  const { setState } = useRoleStore;
  setState({
    loading: true,
  });

  try {
    const { data: result } = await api.get(
      ROUTES.getRoleByIdApi(id),
      false,
      addToast
    );
    if (result) {
      setState({
        roleDetail: result || {},

        loading: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      setState({
        roleDetail: {},

        loading: false,
      });
    }
  } catch (e) {
    return setState({
      roleDetail: {},

      loading: false,
      error: e.messages,
    });
  }
};
export const updateRoleAction = async (data, id, addToast, history) => {
  const { setState } = useRoleStore;
  setState({ loading: true });
  const reqData = { ...data };
  try {
    const { data: result } = await api.patch(
      ROUTES.updateRole(id),
      reqData,
      addToast
    );
    if (result) {
      setState({
        loading: false,
      });
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      history.replace("/admin/role-list");
    } else {
      setState({ loading: false });
      addToast(result?.message, { appearance: "error", autoDismiss: true });
    }
  } catch (e) {
    setState({ loading: false });
    addToast(e?.message, { appearance: "error", autoDismiss: true });
  }
};
export const removePermissions = async (roleId, ids, addToast) => {
  const { setState } = useRoleStore;
  setState({
    loadingDeleteRole: true,
  });

  try {
    const { data: result } = await api.delete(
      ROUTES.removePermissionIds(roleId),
      ids,
      addToast
    );
    if (result?.deletedCount) {
      addToast(result?.message, { appearance: "success", autoDismiss: true });
      getRoleList(addToast, 1, 5);
      return setState({
        loadingDeleteRole: false,
      });
    } else {
      addToast(result?.message, { appearance: "error", autoDismiss: true });

      return setState({
        roleList: [],
        totalCount: 0,

        loadingDeleteRole: false,
      });
    }
  } catch (e) {
    return setState({
      roleList: [],
      totalCount: 0,
      loadingDeleteRole: false,
      error: e.messages,
    });
  }
};

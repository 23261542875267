/*eslint-disable*/
import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import forexlogo from '../../assets/img/forex-logo.png';
// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.js';
import RTLNavbarLinks from 'components/Navbars/RTLNavbarLinks.js';

import styles from 'assets/jss/material-dashboard-react/components/sidebarStyle.js';

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  console.log(props, 'props');
  const classes = useStyles();
  let location = useLocation();
  const [submenuStates, setSubmenuStates] = useState({});
  console.log(submenuStates, 'submenuStates');
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName;
  }
  const { color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = ' ';
        var listItemClasses;
        if (prop.path === '/upgrade-to-pro') {
          activePro = classes.activePro + ' ';
          listItemClasses = classNames({
            [' ' + classes[color]]: true,
          });
        } else {
          listItemClasses = classNames({
            [' ' + classes[color]]: activeRoute(prop.layout + prop.path),
          });
        }
        const whiteFontClasses = classNames({
          [' ' + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });

        // Check if the menu item has children
        const hasChildren = prop.children && prop.children.length > 0;

        return (
          <div key={key} className='menu-item'>
            {/* Main menu item */}
            <NavLink
              to={!prop?.children?.length && prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName='active'
              key={key}
            >
              <ListItem
                button
                className={classes.itemLink + listItemClasses}
                onClick={() => {
                  setSubmenuStates({
                    ...submenuStates,
                    [prop.name]: !submenuStates[prop.name],
                  });
                }}
              >
                {typeof prop.icon === 'string' ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>

            {/* Submenu if the menu item has children */}
            {submenuStates[prop.name] === true && prop?.children?.length > 0 && (
              <List>
                {prop?.children?.map((child, childKey) => (
                  <NavLink
                    to={prop.layout + child.path}
                    className={classes.item}
                    activeClassName='active'
                    key={childKey}
                  >
                    <ListItem button className={classes.itemLink}>
                      <ListItemText
                        primary={props.rtlActive ? child.rtlName : child.name}
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                ))}
              </List>
            )}
          </div>
        );
      })}
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <a
        href=''
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        target='_blank'
      >
        <img src={forexlogo} alt='logo' className='sidebar-logo' />
        {/* <div className={classes.logoImage}>
          <img src={forexlogo} alt='logo' className={classes.img} />
        </div> */}
        {/* {logoText} */}
      </a>
    </div>
  );
  return (
    <div>
      {/* <Hidden mdUp implementation='css'>
        <Drawer
          variant='temporary'
          anchor={props.rtlActive ? 'left' : 'right'}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden> */}
      <Hidden smDown implementation='css'>
        <Drawer
          anchor={props.rtlActive ? 'right' : 'left'}
          variant='permanent'
          open
          classes={{
            paper: classNames(classes.drawerPaper,'sidebar-wrap', {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            null
            // <div
            //   className={classes.background}
            //   style={{ backgroundImage: 'url(' + image + ')' }}
            // />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};

import React, { useEffect } from 'react';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { useRoleStore } from 'store/role/role-store';
import { useHistory, useParams } from 'react-router-dom';
import CardHeader from 'components/Card/CardHeader';
import { viewRoleStyles } from './ViewRoleStyle';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { getRoleById } from 'store/role/role-action';
import { useToasts } from 'react-toast-notifications';
import Loader from 'components/Loader';

const ViewRole = () => {
  const { id } = useParams();

  const { roleDetail, loading } = useRoleStore();
  const commonClasses = viewRoleStyles();
  const { addToast } = useToasts();
  const history = useHistory();
  useEffect(() => {
    getRoleById(addToast, id);
  }, []);
  const handleBackToList = () => {
    history.push('/admin/role-list');
  };
  return (
    <GridContainer>
      {loading ? <Loader /> : null}

      <GridItem xs={12} sm={12} md={8} >
        <Card className='main-wrapper'>
          {/* <CardHeader title='Show Role' /> */}

          <CardHeader color='primary'>
            <h5 className={commonClasses.cardTitleWhite}>Show Role</h5>
            {/* <p className={commonClasses.cardCategoryWhite}>
              Complete your profile
            </p> */}
          </CardHeader>
          <CardContent>
            <Typography variant='h5' component='h5' className='mb-3'>
              Title: {roleDetail?.name}
            </Typography>

            <Typography variant='h6' component='h3' className='mb-2'>
              Permissions:
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {roleDetail?.permissions?.map((permission) => (
                <div key={permission._id} style={{ marginRight: '10px' }}>
                  <Typography color='textSecondary'>
                    {permission?.name}
                  </Typography>
                </div>
              ))}
            </div>
            <Button
              variant='contained'
              color='primary'
              onClick={handleBackToList}
              className='mt-5'
            >
              Back to List
            </Button>
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewRole;

import * as yup from 'yup';

export const pollSchema = yup.object().shape({
  Title: yup.string().trim().required('Poll Title is required'),
  Description: yup.string().trim().required('Description is required'),
  // .min(100, 'Description must be at least 100 characters'),
  pollType: yup.string().trim().required('Poll Type is required'),

  groupId: yup
    .string()
    .trim()
    .when(['pollType'], (pollType, schema) => {
      console.log('pollType', pollType);
      return pollType[0] === 'Group'
        ? schema.required('Group Title is required')
        : schema;
    }),
  // status: yup.string().trim().required('Status is required'),
  startDate: yup.date().required('Start Date is required'),
  endDate: yup.date().required('End Date is required'),
  // options: yup
  //   .array()
  //   .of(yup.string().required('Option is required'))
  //   .min(2, 'At least two options are required')
  //   .nullable(),
  options: yup
    .array()
    .min(2, 'At least two option is required')
    .required('Options are required')
    .nullable(),
});

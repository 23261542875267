import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useGroupStore } from "store/group/group-store";
import { getGroupListAction } from "store/group/group-action";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loader from "components/Loader";
import Modal from "featurecomponents/Modal";
import { deleteGroup } from "store/group/group-action";
import { SearchGroupListAction } from "store/group/group-action";
import {
  Checkbox,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { getGroupType } from "store/group/group-action";
import { getStatus } from "store/group/group-action";
import { sortingOptions } from "shared/field-data";
import { usePostListStyles } from "../Posts/PostStyle";
import SelectField from "components/SelectField/SelectField";
import Pagination from "components/CustomPagination/CustomPagination";
import { updateStatusAction } from "store/group/group-action";

export default function GroupList() {
  const commonClasses = usePostListStyles();
  const { addToast } = useToasts();
  const history = useHistory();

  const {
    groupList,
    loading,
    totalCount,
    statusList,
    groupTypeList,
  } = useGroupStore();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState("");
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState();
  const [status, setStatus] = useState("All");
  const [sortby, setSortby] = useState("a-z");
  const [grouptype, setGrouptype] = useState("All");

  useEffect(() => {
    getGroupListAction(
      addToast,
      currentPage + 1,
      rowsPerPage,
      sortby,
      grouptype,
      status
    );
  }, [currentPage, rowsPerPage, sortby, grouptype, status]);

  useEffect(() => {
    getGroupType(addToast);
    getStatus(addToast);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      /*  if (searchValue) */
      SearchGroupListAction(
        addToast,
        searchValue,
        currentPage + 1,
        rowsPerPage
      );
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    // SearchGroupListAction(
    //   addToast,
    //   event.target.value,
    //   currentPage + 1,
    //   rowsPerPage
    // );
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };
  const handleSortbyChange = (event) => {
    setSortby(event.target.value);
  };
  const handleCancelDelete = () => {
    setSelectedGroups([]);
    setSelectedAll(false);
    setShowDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    deleteGroup({ ids: selectedGroupIds }, addToast);
    setShowDeleteModal(false);
  };

  const handleDeleteSelectedGroups = () => {
    if (selectedGroups.length > 0) {
      setSelectedGroupIds(selectedGroups);
      setShowDeleteModal(true);
    } else {
      addToast("Please select a group to delete", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  //-Confirm modal--
  const handleApprovedSelectedGroups = () => {
    if (selectedGroups.length > 0) {
      handleConfirmation(
        "Are you sure you want to mark the selected group(s) as approved?",
        () => {
          updateStatusAction(
            { groupIds: selectedGroups, status: "Approved" },
            addToast,
            history
          )
            .then(() => {
              setSelectedGroups([]); // Clear the selectedGroups array
            })
            .catch((error) => {
              console.error("Error updating group status:", error);
            });
        }
      );
    } else {
      addToast("Please select a group to mark as approved", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  // For the disapprove action
  const handleDisapprovedSelectedGroups = () => {
    if (selectedGroups.length > 0) {
      handleConfirmation(
        "Are you sure you want to mark the selected group(s) as disapproved?",
        () => {
          updateStatusAction(
            { groupIds: selectedGroups, status: "Disapproved" },
            addToast,
            history
          )
            .then(() => {
              setSelectedGroups([]);
            })
            .catch((error) => {
              console.error("Error updating group status:", error);
            });
        }
      );
    } else {
      addToast("Please select a group to mark as disapproved", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleDeleteGroup = (Id) => {
    setSelectedGroupIds([Id]);
    setShowDeleteModal(true);
  };

  const handleGroupStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handleGroupTypeChange = (event) => {
    setGrouptype(event.target.value);
  };
  const handleCheckboxChange = (event, groupId) => {
    const isChecked = event.target.checked;

    if (groupId === "all") {
      setSelectedAll(isChecked);
      setSelectedGroups(isChecked ? groupList.map((group) => group._id) : []);
    } else {
      setSelectedGroups((prevSelectedGroups) => {
        if (isChecked) {
          return [...prevSelectedGroups, groupId];
        } else {
          return prevSelectedGroups.filter((id) => id !== groupId);
        }
      });
    }
  };
  const handleReview = (id) => {
    history.push(`/admin/review-list/${id}`);
  };
  const handleEdit = (id) => {
    history.push(`/admin/edit-group/${id}`);
  };

  const handleViewPost = () => {
    history.push("/admin/posts");
  };
  const handleConfirmation = (message, action) => {
    setConfirmationMessage(message);
    setConfirmationAction(() => action);
    setShowConfirmationModal(true);
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationModalAction = () => {
    if (confirmationAction) {
      confirmationAction();
    }
    setShowConfirmationModal(false);
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <Modal
        open={showDeleteModal}
        onClose={handleCancelDelete}
        title="Confirm Delete"
        content="Are you sure you want to delete this record?"
        actions={[
          { label: "Cancel", onClick: handleCancelDelete },
          { label: "Delete", onClick: handleConfirmDelete, color: "secondary" },
        ]}
      />
      <Modal
        open={showConfirmationModal}
        onClose={handleConfirmationModalClose}
        title="Confirmation"
        content={confirmationMessage}
        actions={[
          { label: "Cancel", onClick: handleConfirmationModalClose },
          {
            label: "Confirm",
            onClick: handleConfirmationModalAction,
            color: "primary",
          },
        ]}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <div style={{ display: "flex", alignItems: "center" }}>
                <h4>Group List</h4>
                <div
                  style={{
                    width: "fit-content",
                    marginLeft: "auto",
                  }}
                >
                  <Button color="primary" onClick={handleViewPost}>
                    View Post
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  label="Search"
                  placeholder="Search"
                  variant="outlined"
                  value={searchValue}
                  onChange={handleSearchChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDeleteSelectedGroups}
                  style={{ marginLeft: "10px" }}
                >
                  Delete Selected
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApprovedSelectedGroups}
                  style={{ marginLeft: "10px" }}
                >
                  Mark as Approved
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDisapprovedSelectedGroups}
                  style={{ marginLeft: "10px" }}
                >
                  Mark as Disapproved
                </Button>

                <div className={commonClasses.marginRight}>
                  <SelectField
                    label="Group type"
                    value={grouptype}
                    onChange={handleGroupTypeChange}
                    options={groupTypeList}
                  />
                </div>
                <div className={commonClasses.marginRight}>
                  <SelectField
                    label="Status"
                    value={status}
                    onChange={handleGroupStatusChange}
                    options={statusList}
                  />
                </div>
                <SelectField
                  className={commonClasses.marginRight}
                  label="sortby"
                  value={sortby}
                  onChange={handleSortbyChange}
                  options={sortingOptions}
                />
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        key="checkbox-all"
                        checked={selectedAll}
                        onChange={(event) => handleCheckboxChange(event, "all")}
                      />
                    </TableCell>
                    <TableCell>Group Name</TableCell>
                    <TableCell>Group Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupList.map((group) => (
                    <TableRow key={group._id}>
                      <TableCell>
                        <Checkbox
                          key={`checkbox-${group._id}`}
                          checked={selectedGroups.includes(group._id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, group._id)
                          }
                        />
                      </TableCell>
                      <TableCell>{group.title}</TableCell>
                      <TableCell>{group.type}</TableCell>
                      <TableCell>{group.status}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleReview(group._id)}
                          style={{ fontSize: "16px" }}
                        >
                          View Review
                        </IconButton>
                        <IconButton onClick={() => handleEdit(group._id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          key={`delete-${group?._id}`}
                          onClick={() => handleDeleteGroup(group._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <Pagination
        count={Math.ceil(totalCount ?? 0)}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
// @material-ui/core components
// import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useToasts } from "react-toast-notifications";
import Pagination from "components/CustomPagination/CustomPagination";

import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Button,
  CircularProgress,
  InputAdornment,
  Switch,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import Loader from "components/Loader";
import Modal from "featurecomponents/Modal";
import { usePosllListStyles } from "./PollListStyle";
import { getPollList } from "store/poll/poll-action";
import { usePollStore } from "store/poll/poll-store";
import SelectField from "components/SelectField/SelectField";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ROUTES from "utilities/api-routes";
import useSWR from "swr";
import { fetcherSWR } from "services/api";
import { deletePoll } from "store/poll/poll-action";
import OptionsModal from "featurecomponents/PollViewModal/PollViewModal";
import { getPollDetail } from "store/poll/poll-action";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { getPollReportAction } from "store/poll/poll-action";
import { formatDateTime } from "helper";
import { activeInActivePoll } from "store/poll/poll-action";
import { pollStateList } from "shared/field-data";

export default function PollList() {
  const { addToast } = useToasts();
  const commonClasses = usePosllListStyles();

  const {
    pollList,
    totalCount,
    loading,
    pollOptionDetail,
    isModalOpen,
    downloadLoading,
  } = usePollStore();
  const { setState } = usePollStore;
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPostIds, setSelectedPostIds] = useState([]);
  const [status, setStatus] = useState("All");
  const [pollType, setPollType] = useState("All");
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pollState, setPollState] = useState("Running");
  const { data: pollTypeList } = useSWR(
    ROUTES.getPollTypeList(),
    (url) => fetcherSWR(url, false, addToast),
    { revalidateOnFocus: false } // Optional configuration options
  );
  const { data: statusList } = useSWR(
    ROUTES.getPollSatatusList(),
    (url) => fetcherSWR(url, false, addToast),
    { revalidateOnFocus: false } // Optional configuration options
  );
  // const { data: pollStateList } = useSWR(
  //   ROUTES.getPollState(),
  //   (url) => fetcherSWR(url, false, addToast),
  //   { revalidateOnFocus: false } // Optional configuration options
  // );
  // Define function to handle modal close event
  const handleModalClose = () => {
    const { setState } = usePollStore;
    setState({
      isModalOpen: false,
    });
  };

  // Mocked options data
  // const options = [
  //   { _id: '65e00de42a4025efc35b6e83', option: 'mp', votes: 0 },
  //   { _id: '65e00de42a4025efc35b6e84', option: 'up', votes: 0 },
  //   { _id: '65e00de42a4025efc35b6e85', option: 'gujrat', votes: 0 },
  // ];
  const handleSelectAll = () => {
    setSelectedRows(pollList?.map((post) => post._id));
    setSelectedAll(true);
  };

  const handleDeselectAll = () => {
    setSelectedRows([]);
    setSelectedAll(false);
  };

  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      const selectedRoleId = { ids: selectedRows };
      setSelectedPostIds(selectedRows); // Set the selected post IDs
      setShowDeleteModal(true);
      console.log(selectedRoleId, "selectedRoleId");
      //  deletePost(selectedRoleId, addToast);
    } else {
      addToast("Please select a admin to delete", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const handleCheckboxChange = (event, roleId) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, roleId]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== roleId)
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };
  //make a handle delete function

  useEffect(() => {
    getPollList(
      addToast,
      currentPage + 1,
      rowsPerPage,
      pollType,
      status,
      startDate,
      endDate,
      pollState
    );
  }, [currentPage, rowsPerPage, pollType, status, pollState]);

  const handleDownload = (id) => {
    console.log(id);
    getPollReportAction(id, addToast);
  };
  const handleDelete = (Id) => {
    //   const selectedId = { ids: [Id] };
    setSelectedPostIds([Id]); // Set the selected post IDs
    setShowDeleteModal(true);

    //  deletePost(selectedId, addToast); // Add your delete logic here
    // Add your delete logic here
  };
  const handleConfirmDelete = () => {
    // Perform the delete action
    console.log(selectedPostIds, "selectedPostIds");
    deletePoll({ pollIds: selectedPostIds }, addToast);
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setSelectedRows([]);
    setSelectedAll(false);
    setShowDeleteModal(false);
  };

  const handleView = (id) => {
    console.log(id);
    getPollDetail(id, addToast);
  };
  const handleEdit = (id) => {
    console.log(id);
    // getAdminById(addToast, id, history, true);
    history.push(`/admin/edit-poll/${id}`);
  };

  const handleToggle = (id) => {
    activeInActivePoll(id, addToast);
  };
  const handleNewPoll = () => {
    setState({
      isModalOpen: false,
      pollDetail: {},
    });
    history.push("/admin/add-poll");
  };
  const toggleStartDatePicker = () => {
    setOpenStartDatePicker(!openStartDatePicker);
  };

  const toggleEndDatePicker = () => {
    setOpenEndDatePicker(!openEndDatePicker);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setEndDate("");
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    getPollList(
      addToast,
      currentPage + 1,
      rowsPerPage,
      pollType,
      status,
      startDate,
      date,
      pollState
    );
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handleTypeChange = (event) => {
    setPollType(event.target.value);
  };

  const handleStateChange = (event) => {
    setPollState(event.target.value);
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <Modal
        open={showDeleteModal}
        onClose={handleCancelDelete}
        title="Confirm Delete"
        content="Are you sure you want to delete this record?"
        actions={[
          { label: "Cancel", onClick: handleCancelDelete },
          { label: "Delete", onClick: handleConfirmDelete, color: "secondary" },
        ]}
      />
      <OptionsModal
        open={isModalOpen}
        onClose={handleModalClose}
        options={pollOptionDetail}
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={commonClasses.cardTitleWhite}>Poll List</h4>
              <p className={commonClasses.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <div className={commonClasses.buttonstyle}>
                <div className={commonClasses.marginRight}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNewPoll}
                  >
                    Add New Poll
                  </Button>
                </div>
                <div className={commonClasses.marginRight}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      loading ||
                      !Array.isArray(pollList) ||
                      pollList?.length === 0
                    }
                    onClick={handleDeleteSelected}
                  >
                    Delete Selected
                  </Button>
                </div>
                <div className={commonClasses.dateStyle}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Start Date"
                    open={openStartDatePicker}
                    onClickOutside={() => setOpenStartDatePicker(false)}
                    onSelect={() => setOpenStartDatePicker(false)}
                  />
                  <InputAdornment position="end">
                    <IconButton onClick={toggleStartDatePicker}>
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                </div>
                <div className={commonClasses.dateStyle}>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="End Date"
                    minDate={startDate}
                    disabled={!startDate}
                    open={openEndDatePicker}
                    onClickOutside={() => setOpenEndDatePicker(false)}
                    onSelect={() => setOpenEndDatePicker(false)}
                  />
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleEndDatePicker}
                      disabled={!startDate}
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  </InputAdornment>
                </div>
                <div className={commonClasses.marginRight}>
                  <SelectField
                    label="Poll Type"
                    value={pollType}
                    onChange={handleTypeChange}
                    options={
                      Array.isArray(pollTypeList?.data)
                        ? pollTypeList?.data
                        : []
                    }
                  />
                </div>

                <div className={commonClasses.marginRight}>
                  <SelectField
                    label="Poll State"
                    value={pollState}
                    onChange={handleStateChange}
                    options={pollStateList}
                  />
                </div>
                <div>
                  <SelectField
                    label="Status"
                    value={status}
                    onChange={handleStatusChange}
                    options={
                      Array.isArray(statusList?.data) ? statusList?.data : []
                    }
                  />
                </div>
              </div>

              {Array.isArray(pollList) && pollList.length > 0 ? (
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    <Checkbox
                      key="select-all-checkbox"
                      checked={selectedAll}
                      onChange={(event) =>
                        event.target.checked
                          ? handleSelectAll()
                          : handleDeselectAll()
                      }
                    />,
                    //  'Poll ID', // Add ID column
                    "Title",
                    "Poll Type", // Add Poll Type column
                    "Date",
                    // 'Status', // Add Status column

                    "Actions",
                  ]} // Adjusted table headers
                  tableData={(Array.isArray(pollList) ? pollList : []).map(
                    (post) => [
                      <Checkbox
                        key={post?._id}
                        checked={selectedRows.includes(post._id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, post._id)
                        }
                      />, // Add key prop for each element in the array
                      //  post?._id, // ID
                      post?.Title, // Title
                      post?.pollType, // Poll Type
                      post?.createdAt ? formatDateTime(post?.createdAt) : "", // Date

                      // post?.status,

                      <div
                        key={`role-${post._id}`}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <IconButton
                          key={`view-${post?._id}`}
                          onClick={() => handleView(post._id)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          key={`edit-${post?._id}`}
                          onClick={() => handleEdit(post?._id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <Switch
                          key={`switch-${post?._id}`}
                          checked={post?.status == "Active" ? true : false}
                          onChange={() => handleToggle(post?._id)}
                          name="checkedA"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                        <IconButton
                          key={`delete-${post?._id}`}
                          onClick={() => handleDelete(post?._id)}
                        >
                          {/* Add delete column */}
                          <DeleteIcon />
                        </IconButton>
                        {/* //post?.PollState */}
                        {post?.PollState === "Completed" && (
                          <IconButton
                            key={`download-${post?._id}`}
                            disabled={downloadLoading[post?._id]} // Use the loading state for the specific item
                            onClick={() => handleDownload(post?._id)}
                          >
                            {downloadLoading[post?._id] ? (
                              <CircularProgress size={24} /> // Show loading indicator if downloading
                            ) : (
                              <DownloadIcon />
                            )}
                          </IconButton>
                        )}
                      </div>,
                    ]
                  )}
                />
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>Data not found</p>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Pagination
        count={Math.ceil(totalCount ?? 0)}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
}

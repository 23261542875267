import React, { useEffect } from 'react';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useToasts } from 'react-toast-notifications';
import Loader from 'components/Loader';
import { usePostStore } from 'store/posts/post-store';
import { getCommentById } from 'store/posts/post-action';
import { viewCommentStyles } from './ViewCommentStyle';

const ViewComment = () => {
  const { id } = useParams();
  const { commentDetail, loading } = usePostStore();
  const commonClasses = viewCommentStyles();
  const { addToast } = useToasts();
  const history = useHistory();

  useEffect(() => {
    getCommentById(addToast, id);
  }, []);

  const handleBackToList = () => {
    console.log(commentDetail, 'commentDetail');
    history.push(`/admin/view-post/${commentDetail?.posts[0]?._id}`);
  };

  return (
    <GridContainer>
      {loading ? <Loader /> : null}

      <GridItem xs={12} sm={12} md={8}>
        <Card className='main-wrapper'>
          <CardHeader color='primary'>
            <h5 className={commonClasses.cardTitleWhite}>View Comment</h5>
          </CardHeader>
          <CardContent>
            <Typography variant='subtitle1' component='p' className='mb-2'>
              Comment Content: {commentDetail?.content}
            </Typography>

            <Typography variant='subtitle1' component='p' className='mb-2'>
              Author:{' '}
              {commentDetail?.author && commentDetail.author.length > 0
                ? commentDetail?.author[0]?.username
                : 'Unknown'}
            </Typography>

            <Typography variant='subtitle1' component='p' className='mb-2'>
              Date and Time: {commentDetail?.createdAt}
            </Typography>

            <Typography variant='subtitle1' component='p' className='mb-2'>
              Status: {commentDetail?.isApproved ? 'Approved' : 'Disapproved'}
            </Typography>

            <Button
              variant='contained'
              color='primary'
              onClick={handleBackToList}
              className='mt-5'
            >
              Back to List
            </Button>
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewComment;

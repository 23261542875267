import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
/* import { useGroupStore } from "store/group/group-store";
import { getGroupListAction } from "store/group/group-action"; */
import { getReviewListAction } from "store/reviews/review-action";
import { useReviewStore } from "store/reviews/review-store";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loader from "components/Loader";
//import Modal from "featurecomponents/Modal";
//import { deleteGroup } from "store/group/group-action";
import { SearchGroupListAction } from "store/group/group-action";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
/* import DeleteIcon from "@material-ui/icons/Delete"; */
import { getGroupType } from "store/group/group-action";
import { getStatus } from "store/group/group-action";
import { sortingOptions } from "shared/field-data";
import { usePostListStyles } from "../Posts/PostStyle";
import SelectField from "components/SelectField/SelectField";
import Pagination from "components/CustomPagination/CustomPagination";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
//import { updateStatusAction } from "store/group/group-action";

export default function ReviewList() {
  const commonClasses = usePostListStyles();
  const { addToast } = useToasts();
  const history = useHistory();
  const { id } = useParams();
  /*  const {
    groupList,
    loading,
    totalCount,
    statusList,
    groupTypeList,
  } = useGroupStore(); */
  const {
    reviewList,
    loading,
    totalRatings,
    statusList,
    groupTypeList,
  } = useReviewStore();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState("");
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  console.log("setSelectedGroups", setSelectedGroups);
  console.log("selectedAll", selectedAll);
  // const [selectedGroupIds, setSelectedGroupIds] = useState();
  const [status, setStatus] = useState("All");
  const [sortby, setSortby] = useState("a-z");
  const [grouptype, setGrouptype] = useState("All");

  /*  useEffect(() => {
    getGroupListAction(
      addToast,
      currentPage + 1,
      rowsPerPage,
      sortby,
      grouptype,
      status
    );
  }, [currentPage, rowsPerPage, sortby, grouptype, status]); */
  useEffect(() => {
    getReviewListAction(
      addToast,
      id,
      currentPage + 1,
      rowsPerPage,
      sortby,

      status
    );
  }, [id, currentPage, rowsPerPage, sortby, status]);

  useEffect(() => {
    getGroupType(addToast);
    getStatus(addToast);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      /*  if (searchValue) */
      SearchGroupListAction(
        addToast,
        searchValue,
        currentPage + 1,
        rowsPerPage
      );
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };
  const handleSortbyChange = (event) => {
    setSortby(event.target.value);
  };

  const handleGroupStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handleGroupTypeChange = (event) => {
    setGrouptype(event.target.value);
  };
  const handleCheckboxChange = (event, groupId) => {
    const isChecked = event.target.checked;

    if (groupId === "all") {
      setSelectedAll(isChecked);
      setSelectedGroups(isChecked ? reviewList.map((group) => group._id) : []);
    } else {
      setSelectedGroups((prevSelectedGroups) => {
        if (isChecked) {
          return [...prevSelectedGroups, groupId];
        } else {
          return prevSelectedGroups.filter((id) => id !== groupId);
        }
      });
    }
  };

  const handleEdit = (id) => {
    history.push(`/admin/edit-review/${id}`);
  };

  const handleViewPost = () => {
    history.push("/admin/group-list");
  };

  console.log("reviewList", reviewList);
  return (
    <>
      {loading ? <Loader /> : null}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <div style={{ display: "flex", alignItems: "center" }}>
                <h4>Review List</h4>
                <div
                  style={{
                    width: "fit-content",
                    marginLeft: "auto",
                  }}
                >
                  <Button color="primary" onClick={handleViewPost}>
                    BACK
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  label="Search"
                  placeholder="Search"
                  variant="outlined"
                  value={searchValue}
                  onChange={handleSearchChange}
                />

                <div className={commonClasses.marginRight}>
                  <SelectField
                    label="Group type"
                    value={grouptype}
                    onChange={handleGroupTypeChange}
                    options={groupTypeList}
                  />
                </div>
                <div className={commonClasses.marginRight}>
                  <SelectField
                    label="Status"
                    value={status}
                    onChange={handleGroupStatusChange}
                    options={statusList}
                  />
                </div>
                <SelectField
                  className={commonClasses.marginRight}
                  label="sortby"
                  value={sortby}
                  onChange={handleSortbyChange}
                  options={sortingOptions}
                />
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        key="checkbox-all"
                        checked={selectedAll}
                        onChange={(event) => handleCheckboxChange(event, "all")}
                      />
                    </TableCell>
                    <TableCell>Group Name</TableCell>
                    <TableCell>Group Type</TableCell>
                    <TableCell>Review Status</TableCell>
                    <TableCell>Ratings</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviewList.map((group) => (
                    <TableRow key={group._id}>
                      <TableCell>
                        <Checkbox
                          key={`checkbox-${group._id}`}
                          checked={selectedGroups.includes(group._id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, group._id)
                          }
                        />
                      </TableCell>
                      <TableCell>{group.customerId.FirstName}</TableCell>
                      <TableCell>{group.groupId.type}</TableCell>
                      <TableCell>{group.status}</TableCell>
                      <TableCell>{group.ratingValue}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEdit(group._id)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <Pagination
        count={Math.ceil(totalRatings ?? 0)}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
}

import React, { useEffect } from 'react';
// @material-ui/core components
import { yupResolver } from '@hookform/resolvers/yup';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
//import { useToasts } from 'react-toast-notifications';

import { useForm } from 'react-hook-form';
import { useAdminStore } from 'store/admin/admin-store';
import { editAdminStyles } from './AdminEditStyle';
import { adminUpdateInput } from 'shared/field-data';
import { adminEditSchema } from 'validation/admin';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { updateAdminAction } from 'store/admin/admin-action';
import { getAdminById } from 'store/admin/admin-action';
import Loader from 'components/Loader';

export default function AdminEdit() {
  //make a add yupResolver
  const { id } = useParams();

  const commonClasses = editAdminStyles();
  const { adminDetail, loading } = useAdminStore();
  // const { addToast } = useToasts();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(adminEditSchema),
  });
  const { addToast } = useToasts();
  const history = useHistory();
  useEffect(() => {
    getAdminById(addToast, id);
  }, []);
  React.useEffect(() => {
    setValue('username', adminDetail.username);
    setValue('email', adminDetail.email);
  }, [setValue, adminDetail.username, adminDetail.email]);

  const onSubmit = (data) => {
    console.log(data);
    adminDetail.username = data.username;
    adminDetail.email = data.email;

    updateAdminAction(data, adminDetail?._id, addToast, history); // Handle form submission here
  };
  return (
    <div>
      {loading ? <Loader /> : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardHeader color='primary'>
                <h4 className={commonClasses.cardTitleWhite}>Edit User</h4>
                <p className={commonClasses.cardCategoryWhite}>
                  {/* Complete your profile */}
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {adminUpdateInput?.map((input) => (
                    <>
                      <CustomInput
                        key={input.name}
                        // labelText={input.placeholder}
                        id={input.name}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: input.type,
                          ...register(input.name), // Register the input field with react-hook-form
                        }}
                        error={!!errors[input.name]} // Set error state based on whether there are errors for this field
                        success={!errors[input.name]} // Set success state based on whether there are no errors for this field
                      />

                      {errors[input.name] && (
                        <span
                          className='error-message'
                          style={{ color: 'red' }}
                        >
                          {errors[input.name].message}
                        </span>
                      )}
                    </>
                  ))}
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  color='primary'
                  type='submit'
                  disabled={loading}
                  className='btn-theme'
                >
                  Update
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
